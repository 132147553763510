import i18n from 'i18next';

import { ComplaintServices } from '../../services';

import { typeError } from '../../data/enums/error';
import complaintStatus from '../../data/enums/complaint-status';

export const IS_LOADING = 'IS_LOADING:complaint';
export const REQUEST_ERROR = 'REQUEST_ERROR:complaint';
export const IS_CONFIRM_LOADING = 'IS_CONFIRM_LOADING:complaint';
export const IS_GET_BY_ID_LOADING = 'IS_GET_BY_ID_LOADING:complaint';
export const IS_REQUEST_COMPLETED = 'IS_REQUEST_COMPLETED:complaint';

export const GET_VIDEOS_COMPLAINTS = 'GET_VIDEOS_COMPLAINTS:complaint';
export const GET_COMMENTS_COMPLAINTS = 'GET_COMMENTS_COMPLAINTS:complaint';
export const GET_PROFILES_COMPLAINTS = 'GET_PROFILES_COMPLAINTS:complaint';

export const GET_VIDEO_COMPLAINT_BY_ID = 'GET_VIDEO_COMPLAINT_BY_ID:complaint';
export const GET_COMMENT_COMPLAINT_BY_ID = 'GET_COMMENT_COMPLAINT_BY_ID:complaint';
export const GET_PROFILE_COMPLAINT_BY_ID = 'GET_PROFILE_COMPLAINT_BY_ID:complaint';

export const IS_VIDEO_REQUESTED_SUCCESSFULLY = 'IS_VIDEO_REQUESTED_SUCCESSFULLY: complaint';
export const IS_COMMENT_REQUESTED_SUCCESSFULLY = 'IS_COMMENT_REQUESTED_SUCCESSFULLY: complaint';
export const IS_PROFILE_REQUESTED_SUCCESSFULLY = 'IS_PROFILE_REQUESTED_SUCCESSFULLY: complaint';

const startRequestGetById = (id) => (dispatch) => {
  dispatch({ type: IS_GET_BY_ID_LOADING, payload: [id] });
};

const endRequestGetById = () => (dispatch) => {
  dispatch({ type: REQUEST_ERROR, payload: '' });
  dispatch({ type: IS_GET_BY_ID_LOADING, payload: [] });
  dispatch({ type: IS_VIDEO_REQUESTED_SUCCESSFULLY, payload: false });
  dispatch({ type: IS_COMMENT_REQUESTED_SUCCESSFULLY, payload: false });
  dispatch({ type: IS_PROFILE_REQUESTED_SUCCESSFULLY, payload: false });
};

const startRequest = () => (dispatch) => {
  dispatch({ type: IS_CONFIRM_LOADING, payload: true });
  dispatch({ type: IS_REQUEST_COMPLETED, payload: false });
};

const endRequest = () => (dispatch) => {
  dispatch({ type: REQUEST_ERROR, payload: '' });
  dispatch({ type: IS_CONFIRM_LOADING, payload: false });
  dispatch({ type: IS_REQUEST_COMPLETED, payload: true });
};

export const getVideosComplaints = (data) => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    const { complaints } = await ComplaintServices.getVideosComplaints(data);

    dispatch({ type: GET_VIDEOS_COMPLAINTS, payload: complaints });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
  }
};

export const getCommentsComplaints = (data) => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    const { complaints } = await ComplaintServices.getCommentsComplaints(data);

    dispatch({ type: GET_COMMENTS_COMPLAINTS, payload: complaints });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
  }
};

export const getProfilesComplaints = (data) => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    const { complaints } = await ComplaintServices.getProfilesComplaints(data);

    dispatch({ type: GET_PROFILES_COMPLAINTS, payload: complaints });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
  }
};

export const getVideoComplaintById = (id) => async (dispatch) => {
  try {
    dispatch(startRequestGetById(id));

    const video = await ComplaintServices.getVideoComplaintById(id);

    dispatch({ type: GET_VIDEO_COMPLAINT_BY_ID, payload: video });
    dispatch({ type: IS_VIDEO_REQUESTED_SUCCESSFULLY, payload: true });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequestGetById());
  }
};

export const getCommentComplaintById = (id) => async (dispatch) => {
  try {
    dispatch(startRequestGetById(id));

    const comment = await ComplaintServices.getCommentComplaintById(id);

    dispatch({ type: GET_COMMENT_COMPLAINT_BY_ID, payload: comment });
    dispatch({ type: IS_COMMENT_REQUESTED_SUCCESSFULLY, payload: true });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequestGetById());
  }
};

export const getProfileComplaintById = (id) => async (dispatch) => {
  try {
    dispatch(startRequestGetById(id));

    const profile = await ComplaintServices.getProfileComplaintById(id);

    dispatch({ type: GET_PROFILE_COMPLAINT_BY_ID, payload: profile });
    dispatch({ type: IS_PROFILE_REQUESTED_SUCCESSFULLY, payload: true });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequestGetById());
  }
};

export const processVideoComplaint = (data) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await ComplaintServices.processVideoComplaint(data);

    dispatch(getVideosComplaints({ status: complaintStatus.PENDING }));
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};

export const processCommentComplaint = (data) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await ComplaintServices.processCommentComplaint(data);

    dispatch(getCommentsComplaints({ status: complaintStatus.PENDING }));
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};

export const processProfileComplaint = (data) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await ComplaintServices.processProfileComplaint(data);

    dispatch(getProfilesComplaints({ status: complaintStatus.PENDING }));
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};
