import { UserActions } from '../actions';

const initialState = {
  errorMessage: '',
  isLoading: false,
  isConfirmLoading: false,
  isRequestCompleted: false,

  users: [],
  selectedMenu: [''],
  minePermissions: [],
  hasAdsPermission: false,
  hasUsersPermission: false,
  hasPlacesPermission: false,
  hasTVVideosPermission: false,
  hasComplaintsPermission: false,
  hasIndicatorsPermission: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case UserActions.GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case UserActions.SET_SELECTED_MENU:
      return {
        ...state,
        selectedMenu: action.payload,
      };
    case UserActions.GET_MINE_PERMISSIONS:
      return {
        ...state,
        minePermissions: action.payload,
      };
    case UserActions.SET_ADS_PERMISSIONS:
      return {
        ...state,
        hasAdsPermission: action.payload,
      };
    case UserActions.SET_USERS_PERMISSIONS:
      return {
        ...state,
        hasUsersPermission: action.payload,
      };
    case UserActions.SET_PLACES_PERMISSIONS:
      return {
        ...state,
        hasPlacesPermission: action.payload,
      };
    case UserActions.SET_TV_VIDEOS_PERMISSIONS:
      return {
        ...state,
        hasTVVideosPermission: action.payload,
      };
    case UserActions.SET_COMPLAINTS_PERMISSIONS:
      return {
        ...state,
        hasComplaintsPermission: action.payload,
      };
    case UserActions.SET_INDICATORS_PERMISSIONS:
      return {
        ...state,
        hasIndicatorsPermission: action.payload,
      };
    case UserActions.IS_CONFIRM_LOADING:
      return {
        ...state,
        isConfirmLoading: action.payload,
      };
    case UserActions.IS_REQUEST_COMPLETED:
      return {
        ...state,
        isRequestCompleted: action.payload,
      };
    case UserActions.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case UserActions.REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default user;
