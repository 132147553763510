import api from './api';

export const getPlaces = async () => {
  const response = await api.get('/admin/places');

  return (response && response.data) || {};
};

export const getPlacesName = async () => {
  const response = await api.get('/admin/places/plain-list');

  return (response && response.data) || {};
};

export const addPlace = async (place) => {
  const response = await api.post('/admin/places', place);

  return (response && response.data) || {};
};

export const updatePlace = async (place) => {
  const response = await api.put(`/admin/places/${place.id}`, place);

  return (response && response.data) || {};
};

export const getTVPlaces = async () => {
  const response = await api.get('/admin/tv/places');

  return (response && response.data) || {};
};

export const addPrivateUsersByCSV = async ({ placeId, formData }) => {
  const response = await api.post(
    `/admin/places/${placeId}/private-users/csv`,
    formData,
  );

  return (response && response.data) || {};
};

export const addPrivateUser = async ({ placeId, username }) => {
  const response = await api.post(`/admin/places/${placeId}/private-users`, {
    username,
  });

  return (response && response.data) || {};
};

export const getPrivateUsers = async (placeId) => {
  const response = await api.get(`/admin/places/${placeId}/private-users`);

  return (response && response.data) || {};
};

export const deletePrivateUser = async ({ placeId, username }) => {
  const response = await api.delete(`/admin/places/${placeId}/private-users`, {
    data: { username },
  });

  return (response && response.data) || {};
};

export const deleteField = async (fieldId) => {
  const response = await api.delete(`/admin/places/fields/${fieldId}`);

  return (response && response.data) || {};
};
