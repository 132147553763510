const Routes = {
  HOME: '/',
  LOGIN: '/login',
  COMPLAINTS: '/complaints',
  PLACES: '/places',
  ADS: '/ads',
  TV_VIDEOS: '/tv-videos',
  INDICATORS: '/indicators',
  USERS: '/users',
};

export default Routes;
