import React from 'react';
import styled from 'styled-components';

import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';
import { FiX, FiFile } from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8rem;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 59%;
  min-width: 700px;
  margin-bottom: 1rem;
  padding: 0 1rem 0.5rem;
  border-bottom: 1px solid var(--color-form-border);
`;

export const HeaderTitle = styled.span`
  font-size: 2rem;
  color: ${({ isSelected }) => (
    isSelected ? 'var(--color-primary)' : 'var(--color-white)'
  )};

  cursor: pointer;  
`;

export const Widget = styled.div`
  position: relative;
  width: 60%;
  min-width: 700px;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 4rem;
  border-radius: 0.8rem;
  border: 1px solid var(--color-form-border);
  background: var(--color-form-background);
`;

export const WidgetHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--color-form-border);
`;

export const TableTitle = styled.span`
  width: 50%;
  margin-left: 2rem;
  color: var(--color-white);
`;

export const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding: 10px 16px;
  margin-right: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.4rem;
  font: 400 1.6rem Maven Pro;
  color: var(--color-black);
  background: var(--color-primary);

  :hover {
    background: var(--color-primary-dark);
  }
`;

export const Complaint = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: var(--color-background);
  border-bottom: 1px solid var(--color-form-border);

  cursor: pointer;
`;

export const ReasonWrapper = styled.div`
  width: 42%;
`;

export const DateWrapper = styled.div`
  width: 50%;
`;

export const Reason = styled.span`
  font-size: 1.6rem;
  color: var(--color-white);
  text-transform: capitalize;
`;

export const Date = styled.span`
  font-size: 1.6rem;
  color: var(--color-white);
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const EmptyStateText = styled.span`
  font-size: 2.2rem;
  color: var(--color-white);
`;

const ReactModalAdapter = ({ className, modalClassName, ...props }) => (
  <ReactModal
    className={modalClassName}
    portalClassName={className}
    bodyOpenClassName="portalOpen"
    {...props}
  />
);

export const Modal = styled(ReactModalAdapter).attrs({
  modalClassName: 'Modal',
  overlayClassName: 'Overlay',
})`
  & .Overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  & .Modal {
    display: flex;
    flex: 1;

    width: 100%;
    padding: 2rem 3rem;
    border-radius: 4px;
    border: 1px solid var(--color-form-border);
    background: var(--color-form-background);

    outline: none;

    position: absolute;
    top: 1%;
  }
`;

export const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: flex-end;
`;

export const Close = styled(FiX).attrs({
  size: 22,
  color: 'var(--color-white)',
})`
  cursor: pointer;
`;

export const ReporterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const ReporterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Place = styled.label`
  margin-bottom: 1rem;
  color: var(--color-white);
`;

export const ReportedBy = styled.span`
  color: var(--color-white);
`;

export const Label = styled.label`
  color: var(--color-white);
`;

export const Comment = styled.label`
  margin: 0.5rem 0;
  color: var(--color-white);
`;

export const LabelRight = styled.label`
  text-align: right;
  color: var(--color-white);
`;

export const Proceed = styled.label`
  text-align: right;
  color: ${({ isProceed }) => (
    isProceed ? 'var(--color-primary)' : 'var(--color-white)'
  )};
`;

export const Resolver = styled.label`
  margin: 1rem 0;
  color: var(--color-white);
`;

export const Reporter = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const Name = styled.span`
  margin-left: 1rem;
  color: var(--color-white);
`;

export const VideoPlayer = styled(ReactPlayer)``;

export const ModalFooter = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProceedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 4rem;
  border-radius: 0.4rem;
  font: 400 1.6rem Maven Pro;
  color: var(--color-black);
  background: var(--color-primary);

  :hover {
    background: var(--color-primary-dark);
  }
`;

export const DontProceedButton = styled(ProceedButton)`
  margin-right: 2rem;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  background: var(--color-form-background);

  :hover {
    color: var(--color-black);
    background: var(--color-primary);
  }
`;

export const File = styled(FiFile).attrs(({ isProceed }) => ({
  size: 22,
  color: isProceed ? 'var(--color-red)' : 'var(--color-primary)',
}))`
  cursor: pointer;
`;
