import React from 'react';

import { toast } from 'react-toastify';
import ptBR from 'date-fns/locale/pt-BR';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../components/Loading/Loading';
import Tooltip from '../../components/Tooltip/Tooltip';
import LoggedArea from '../../components/LoggedArea/LoggedArea';

import menu from '../../data/enums/menu';
import Feature from '../../data/enums/feature';
import Complaint from '../../data/enums/complaint';
import Permission from '../../data/enums/permission';
import complaintStatus from '../../data/enums/complaint-status';

import { ComplaintActions, UserActions } from '../../redux/actions';

import * as S from './styles';

const ComplaintsPage = () => {
  const {
    isLoading,
    errorMessage,
    videoComplaint,
    commentComplaint,
    videosComplaints,
    isConfirmLoading,
    isGetByIdLoading,
    profileComplaint,
    commentsComplaints,
    isRequestCompleted,
    profilesComplaints,
    isVideoRequestedSuccessfully,
    isCommentRequestedSuccessfully,
    isProfileRequestedSuccessfully,
  } = useSelector(({ complaint }) => complaint);

  const { minePermissions } = useSelector(({ user }) => user);

  const hasAdminPermission = minePermissions
    && minePermissions.find(({ feature, permission }) => {
      return feature === Feature.COMPLAINTS && permission === Permission.ADMIN;
    });

  const widgetRef = React.useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedComplaint, setSelectedComplaint] = React.useState([]);
  const [visibleVideoModal, setVisibleVideoModal] = React.useState(false);
  const [showDoneComplaints, setShowDoneComplaints] = React.useState(false);
  const [selectedTitle, setSelectedTitle] = React.useState(Complaint.VIDEOS);
  const [visibleCommentModal, setVisibleCommentModal] = React.useState(false);
  const [visibleProfileModal, setVisibleProfileModal] = React.useState(false);

  const complaints = [
    { name: t('videos'), value: Complaint.VIDEOS },
    { name: t('comments'), value: Complaint.COMMENTS },
    { name: t('profiles'), value: Complaint.PROFILES },
  ];

  const parseComplaintValueToNumber = () => {
    switch (selectedTitle) {
      case Complaint.COMMENTS:
        return t('comments');
      case Complaint.PROFILES:
        return t('profiles');
      default:
        return t('videos');
    }
  };

  React.useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  React.useEffect(() => {
    if (isVideoRequestedSuccessfully) {
      window.scrollTo(0, 0);
      setVisibleVideoModal(true);
    }
  }, [isVideoRequestedSuccessfully]);

  React.useEffect(() => {
    if (isCommentRequestedSuccessfully) {
      window.scrollTo(0, 0);
      setVisibleCommentModal(true);
    }
  }, [isCommentRequestedSuccessfully]);

  React.useEffect(() => {
    if (isProfileRequestedSuccessfully) {
      window.scrollTo(0, 0);
      setVisibleProfileModal(true);
    }
  }, [isProfileRequestedSuccessfully]);

  React.useEffect(() => {
    if (isRequestCompleted) {
      setVisibleVideoModal(false);
      setShowDoneComplaints(false);
      setVisibleCommentModal(false);
      setVisibleProfileModal(false);
    }
  }, [isRequestCompleted]);

  React.useEffect(() => {
    dispatch(UserActions.setSelectedMenu(menu.COMPLAINTS));

    if (showDoneComplaints) {
      dispatch(
        ComplaintActions.getVideosComplaints({
          status: complaintStatus.DONE,
        }),
      );

      dispatch(
        ComplaintActions.getCommentsComplaints({
          status: complaintStatus.DONE,
        }),
      );

      dispatch(
        ComplaintActions.getProfilesComplaints({
          status: complaintStatus.DONE,
        }),
      );
    } else {
      dispatch(
        ComplaintActions.getVideosComplaints({
          status: complaintStatus.PENDING,
        }),
      );

      dispatch(
        ComplaintActions.getCommentsComplaints({
          status: complaintStatus.PENDING,
        }),
      );

      dispatch(
        ComplaintActions.getProfilesComplaints({
          status: complaintStatus.PENDING,
        }),
      );
    }
  }, [showDoneComplaints]);

  React.useEffect(() => {
    switch (selectedTitle) {
      case Complaint.COMMENTS:
        setSelectedComplaint(commentsComplaints);
        break;
      case Complaint.PROFILES:
        setSelectedComplaint(profilesComplaints);
        break;
      default:
        setSelectedComplaint(videosComplaints);
        break;
    }
  }, [videosComplaints, commentsComplaints, profilesComplaints]);

  const handleSelectedComplaint = (complaint) => {
    setSelectedTitle(complaint.value);

    switch (complaint.value) {
      case Complaint.COMMENTS:
        setSelectedComplaint(commentsComplaints);
        break;
      case Complaint.PROFILES:
        setSelectedComplaint(profilesComplaints);
        break;
      default:
        setSelectedComplaint(videosComplaints);
        break;
    }
  };

  const handleGetComplaintById = (complaint) => {
    if (hasAdminPermission) {
      switch (selectedTitle) {
        case Complaint.COMMENTS:
          dispatch(ComplaintActions.getCommentComplaintById(complaint.id));
          break;
        case Complaint.PROFILES:
          dispatch(ComplaintActions.getProfileComplaintById(complaint.id));
          break;
        default:
          dispatch(ComplaintActions.getVideoComplaintById(complaint.id));
          break;
      }
    } else {
      toast.error(t('noPermission'));
    }
  };

  const onComplaintDontProceed = () => {
    switch (selectedTitle) {
      case Complaint.COMMENTS:
        dispatch(
          ComplaintActions.processCommentComplaint({
            id: commentComplaint.id,
            proceed: false,
          }),
        );
        break;
      case Complaint.PROFILES:
        dispatch(
          ComplaintActions.processProfileComplaint({
            id: profileComplaint.id,
            proceed: false,
          }),
        );
        break;
      default:
        dispatch(
          ComplaintActions.processVideoComplaint({
            id: videoComplaint.id,
            proceed: false,
          }),
        );
        break;
    }
  };

  const onComplaintProceed = () => {
    switch (selectedTitle) {
      case Complaint.COMMENTS:
        dispatch(
          ComplaintActions.processCommentComplaint({
            id: commentComplaint.id,
            proceed: true,
          }),
        );
        break;
      case Complaint.PROFILES:
        dispatch(
          ComplaintActions.processProfileComplaint({
            id: profileComplaint.id,
            proceed: true,
          }),
        );
        break;
      default:
        dispatch(
          ComplaintActions.processVideoComplaint({
            id: videoComplaint.id,
            proceed: true,
          }),
        );
        break;
    }
  };

  return (
    <LoggedArea>
      <S.Container>
        {isLoading ? (
          <Loading />
        ) : (
          <S.Content>
            <S.Header>
              {complaints.map((complaint) => (
                <S.HeaderTitle
                  key={String(complaint.value)}
                  isSelected={selectedTitle === complaint.value}
                  onClick={() => handleSelectedComplaint(complaint)}
                >
                  {complaint.name}
                </S.HeaderTitle>
              ))}
            </S.Header>

            <S.Widget ref={widgetRef}>
              <S.WidgetHeader>
                <S.TableTitle>{t('reason')}</S.TableTitle>

                <S.TableTitle>{t('complaintDate')}</S.TableTitle>

                <S.ToggleButton
                  onClick={() => setShowDoneComplaints(!showDoneComplaints)}
                >
                  {showDoneComplaints ? t('seePendings') : t('seeResolved')}
                </S.ToggleButton>
              </S.WidgetHeader>

              {selectedComplaint && selectedComplaint.length > 0 ? (
                selectedComplaint.map((complaint) => (
                  <S.Complaint
                    key={complaint.id}
                    onClick={() => handleGetComplaintById(complaint)}
                  >
                    <S.ReasonWrapper>
                      <S.Reason>{complaint.complaint}</S.Reason>
                    </S.ReasonWrapper>
                    <S.DateWrapper>
                      <S.Date>
                        {format(
                          parseISO(complaint.createdAt),
                          "dd/MM/yy 'às' HH:mm'h'",
                          {
                            locale: ptBR,
                          },
                        )}
                      </S.Date>
                    </S.DateWrapper>

                    {isGetByIdLoading.includes(complaint.id) ? (
                      <Loading
                        type="spin"
                        width={20}
                        height={20}
                        color="#ffea36"
                      />
                    ) : (
                      <Tooltip title={t('analyze')}>
                        <S.File isProceed={complaint.proceed} />
                      </Tooltip>
                    )}
                  </S.Complaint>
                ))
              ) : (
                <S.EmptyState>
                  <S.EmptyStateText>
                    {t('noPendingComplaint').replace(
                      '{value}',
                      parseComplaintValueToNumber(),
                    )}
                  </S.EmptyStateText>
                </S.EmptyState>
              )}

              {widgetRef && widgetRef.current ? (
                <>
                  <S.Modal
                    isOpen={visibleVideoModal}
                    parentSelector={() => widgetRef.current}
                    onRequestClose={() => setVisibleVideoModal(false)}
                  >
                    <S.ModalContainer>
                      <S.ModalHeader>
                        <S.Close onClick={() => setVisibleVideoModal(false)} />
                      </S.ModalHeader>

                      <S.ReporterContainer>
                        <S.ReporterWrapper>
                          {videoComplaint.recordedAt ? (
                            <S.Place>
                              {`${videoComplaint.placeName} - ${format(
                                parseISO(videoComplaint.recordedAt),
                                "dd/MM/yy 'às' HH:mm'h'",
                                {
                                  locale: ptBR,
                                },
                              )}`}
                            </S.Place>
                          ) : null}

                          <S.ReportedBy>{`${t('reportedBy')}:`}</S.ReportedBy>

                          <S.Reporter>
                            <S.Avatar src={videoComplaint.userAvatar} />

                            <S.Name>{videoComplaint.userName}</S.Name>
                          </S.Reporter>
                        </S.ReporterWrapper>

                        {videoComplaint.status === complaintStatus.DONE ? (
                          <S.ReporterWrapper>
                            <S.LabelRight>{`${t('resolvedBy')}:`}</S.LabelRight>

                            <S.Resolver>
                              {`${videoComplaint.closedBy} - ${format(
                                parseISO(videoComplaint.closedAt),
                                "dd/MM/yy 'às' HH:mm'h'",
                                {
                                  locale: ptBR,
                                },
                              )}`}
                            </S.Resolver>

                            <S.Proceed isProceed={videoComplaint.proceed}>
                              {videoComplaint.proceed
                                ? t('proceed')
                                : t('dontProceed')}
                            </S.Proceed>
                          </S.ReporterWrapper>
                        ) : null}
                      </S.ReporterContainer>

                      <S.VideoPlayer
                        playing
                        url={videoComplaint.url}
                        width="100%"
                        height="100%"
                        controls
                        onError={() => toast.error(t('videoNotFound'))}
                      />

                      <S.ModalFooter>
                        {isConfirmLoading ? (
                          <Loading
                            type="spin"
                            width={26}
                            height={26}
                            color="#ffea36"
                          />
                        ) : (
                          <S.ButtonsWrapper>
                            <S.DontProceedButton
                              onClick={() => onComplaintDontProceed()}
                            >
                              {t('dontProceed')}
                            </S.DontProceedButton>

                            <S.ProceedButton
                              onClick={() => onComplaintProceed()}
                            >
                              {t('proceed')}
                            </S.ProceedButton>
                          </S.ButtonsWrapper>
                        )}
                      </S.ModalFooter>
                    </S.ModalContainer>
                  </S.Modal>

                  <S.Modal
                    isOpen={visibleCommentModal}
                    parentSelector={() => widgetRef.current}
                    onRequestClose={() => setVisibleCommentModal(false)}
                  >
                    <S.ModalContainer>
                      <S.ModalHeader>
                        <S.Close
                          onClick={() => setVisibleCommentModal(false)}
                        />
                      </S.ModalHeader>

                      <S.ReporterContainer>
                        <S.ReporterWrapper>
                          <S.ReportedBy>{`${t('reportedBy')}:`}</S.ReportedBy>

                          <S.Reporter>
                            <S.Avatar src={commentComplaint.userAvatar} />

                            <S.Name>{commentComplaint.userName}</S.Name>
                          </S.Reporter>
                        </S.ReporterWrapper>

                        {commentComplaint.status === complaintStatus.DONE ? (
                          <S.ReporterWrapper>
                            <S.LabelRight>{`${t('resolvedBy')}:`}</S.LabelRight>

                            <S.Resolver>
                              {`${commentComplaint.closedBy} - ${format(
                                parseISO(commentComplaint.closedAt),
                                "dd/MM/yy 'às' HH:mm'h'",
                                {
                                  locale: ptBR,
                                },
                              )}`}
                            </S.Resolver>

                            <S.Proceed isProceed={commentComplaint.proceed}>
                              {commentComplaint.proceed
                                ? t('proceed')
                                : t('dontProceed')}
                            </S.Proceed>
                          </S.ReporterWrapper>
                        ) : null}
                      </S.ReporterContainer>

                      <S.ReporterWrapper>
                        <S.ReportedBy>{`${t('commentedBy')}:`}</S.ReportedBy>

                        <S.Reporter>
                          <S.Avatar src={commentComplaint.commentUserAvatar} />

                          <S.Name>{commentComplaint.commentUserName}</S.Name>
                        </S.Reporter>
                      </S.ReporterWrapper>

                      <S.ReportedBy>{`${t('comment')}:`}</S.ReportedBy>

                      <S.Comment>{commentComplaint.comment}</S.Comment>

                      <S.ModalFooter>
                        {isConfirmLoading ? (
                          <Loading
                            type="spin"
                            width={26}
                            height={26}
                            color="#ffea36"
                          />
                        ) : (
                          <S.ButtonsWrapper>
                            <S.DontProceedButton
                              onClick={() => onComplaintDontProceed()}
                            >
                              {t('dontProceed')}
                            </S.DontProceedButton>

                            <S.ProceedButton
                              onClick={() => onComplaintProceed()}
                            >
                              {t('proceed')}
                            </S.ProceedButton>
                          </S.ButtonsWrapper>
                        )}
                      </S.ModalFooter>
                    </S.ModalContainer>
                  </S.Modal>

                  <S.Modal
                    isOpen={visibleProfileModal}
                    parentSelector={() => widgetRef.current}
                    onRequestClose={() => setVisibleProfileModal(false)}
                  >
                    <S.ModalContainer>
                      <S.ModalHeader>
                        <S.Close
                          onClick={() => setVisibleProfileModal(false)}
                        />
                      </S.ModalHeader>

                      <S.ReporterContainer>
                        <S.ReporterWrapper>
                          <S.ReportedBy>{`${t('reportedBy')}:`}</S.ReportedBy>

                          <S.Reporter>
                            <S.Avatar src={profileComplaint.userAvatar} />

                            <S.Name>{profileComplaint.userName}</S.Name>
                          </S.Reporter>
                        </S.ReporterWrapper>

                        {profileComplaint.status === complaintStatus.DONE ? (
                          <S.ReporterWrapper>
                            <S.LabelRight>{`${t('resolvedBy')}:`}</S.LabelRight>

                            <S.Resolver>
                              {`${profileComplaint.closedBy} - ${format(
                                parseISO(profileComplaint.closedAt),
                                "dd/MM/yy 'às' HH:mm'h'",
                                {
                                  locale: ptBR,
                                },
                              )}`}
                            </S.Resolver>

                            <S.Proceed isProceed={profileComplaint.proceed}>
                              {profileComplaint.proceed
                                ? t('proceed')
                                : t('dontProceed')}
                            </S.Proceed>
                          </S.ReporterWrapper>
                        ) : null}
                      </S.ReporterContainer>

                      <S.ReporterWrapper>
                        <S.ReportedBy>
                          {`${t('reportedProfile')}:`}
                        </S.ReportedBy>

                        <S.Reporter>
                          <S.Avatar src={profileComplaint.profileUserAvatar} />

                          <S.Name>{profileComplaint.profileUserName}</S.Name>
                        </S.Reporter>
                      </S.ReporterWrapper>

                      <S.ModalFooter>
                        {isConfirmLoading ? (
                          <Loading
                            type="spin"
                            width={26}
                            height={26}
                            color="#ffea36"
                          />
                        ) : (
                          <S.ButtonsWrapper>
                            <S.DontProceedButton
                              onClick={() => onComplaintDontProceed()}
                            >
                              {t('dontProceed')}
                            </S.DontProceedButton>

                            <S.ProceedButton
                              onClick={() => onComplaintProceed()}
                            >
                              {t('proceed')}
                            </S.ProceedButton>
                          </S.ButtonsWrapper>
                        )}
                      </S.ModalFooter>
                    </S.ModalContainer>
                  </S.Modal>
                </>
              ) : null}
            </S.Widget>
          </S.Content>
        )}
      </S.Container>
    </LoggedArea>
  );
};

export default ComplaintsPage;
