import { IndicatorActions } from '../actions';

const initialState = {
  errorMessage: '',
  isLoading: false,

  usersLog: {},
  videosLog: {},
};

const tvVideo = (state = initialState, action) => {
  switch (action.type) {
    case IndicatorActions.GET_USERS_INDICATORS:
      return {
        ...state,
        usersLog: action.payload,
      };
    case IndicatorActions.GET_VIDEOS_INDICATORS:
      return {
        ...state,
        videosLog: action.payload,
      };
    case IndicatorActions.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case IndicatorActions.REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default tvVideo;
