import React from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AuthButton from '../Button/AuthButton';

import Storage, { keys } from '../../data/localStorage';

import { AuthActions } from '../../redux/actions';

import * as S from './styles';

const UserModal = ({ isOpen, onClose }) => {
  const email = Storage.find(keys.EMAIL);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <S.Modal isOpen={isOpen} onRequestClose={onClose}>
      <S.Main>
        <S.Avatar>
          {(email && email.charAt(0)) || '?'}
        </S.Avatar>

        <S.Email>{email || t('emailNotFound')}</S.Email>
      </S.Main>

      <S.Footer>
        <AuthButton onClick={() => dispatch(AuthActions.onLogout())}>
          {t('logout')}
        </AuthButton>
      </S.Footer>
    </S.Modal>
  );
};

export default UserModal;
