import React from 'react';

import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../components/Loading/Loading';
import Tooltip from '../../components/Tooltip/Tooltip';
import LoggedArea from '../../components/LoggedArea/LoggedArea';

import menu from '../../data/enums/menu';
import Feature from '../../data/enums/feature';
import Permission from '../../data/enums/permission';
import { placeType } from '../../data/enums/place-type';

import { PlaceActions, UserActions } from '../../redux/actions';

import { zipCodeMask, onlyDigits, usernameMask } from '../../utils/maskedInput';

import * as S from './styles';

const PlacesPage = () => {
  const {
    places,
    isLoading,
    isUploading,
    errorMessage,
    isAddingUser,
    privateUsers,
    isDeletingUser,
    isGettingUsers,
    isDeletingField,
    isConfirmLoading,
    isRequestCompleted,
  } = useSelector(({ place }) => place);
  const { users } = useSelector(({ user }) => user);

  const { minePermissions } = useSelector(({ user }) => user);

  const [city, setCity] = React.useState('');
  const [name, setName] = React.useState('');
  const [notes, setNotes] = React.useState('');
  const [state, setState] = React.useState('');
  const [fields, setFields] = React.useState([]);
  const [sports, setSports] = React.useState([]);
  const [owners, setOwners] = React.useState([]);
  const [address, setAddress] = React.useState('');
  const [zipCode, setZipCode] = React.useState('');
  const [placeId, setPlaceId] = React.useState(null);
  const [username, setUsername] = React.useState('');
  const [fieldName, setFieldName] = React.useState('');
  const [activated, setActivated] = React.useState(true);
  const [bucketName, setBucketName] = React.useState('');
  const [complement, setComplement] = React.useState('');
  const [searchName, setSearchName] = React.useState('');
  const [userIndex, setUserIndex] = React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isPrivate, setIsPrivate] = React.useState(false);
  const [temlanceId, setTemlanceId] = React.useState(null);
  const [tvFtpFolder, setTvFtpFolder] = React.useState('');
  const [neighborhood, setNeighborhood] = React.useState('');
  const [addressNumber, setAddressNumber] = React.useState('');
  const [selectedField, setSelectedField] = React.useState({});
  const [isEditingField, setIsEditingField] = React.useState(false);
  const [showAddOrEditModal, setShowAddOrEditModal] = React.useState(false);
  const [selectedPlaceType, setSelectedPlaceType] = React.useState('public');
  const [showPrivateUsersModal, setShowPrivateUsersModal] = React.useState(false);

  const fileMaxSize = 10 * 1024 * 1024; // 10 MB

  const widgetRef = React.useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sportsList = [
    { name: t('soccer'), value: 'soccer' },
    { name: t('futsal'), value: 'futsal' },
    { name: t('handball'), value: 'handball' },
    { name: t('basketball'), value: 'basketball' },
    { name: t('footvolley'), value: 'footvolley' },
  ];

  const hasAdminPermission = minePermissions
    && minePermissions.find(
      ({ feature, permission }) => feature === Feature.PLACES && permission === Permission.ADMIN,
    );

  const hasOwnerPermission = minePermissions
    && minePermissions.find(
      ({ feature, permission }) => feature === Feature.PLACES && permission === Permission.PLACE_OWNER,
    );

  const cleanInputs = () => {
    setCity('');
    setName('');
    setNotes('');
    setState('');
    setFields([]);
    setOwners([]);
    setSports([]);
    setAddress('');
    setZipCode('');
    setFieldName('');
    setBucketName('');
    setComplement('');
    setSearchName('');
    setTemlanceId('');
    setTvFtpFolder('');
    setIsPrivate(false);
    setNeighborhood('');
    setAddressNumber('');
    setSelectedPlaceType(placeType.PUBLIC);
  };

  React.useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  React.useEffect(() => {
    if (isRequestCompleted) {
      cleanInputs();
      setIsEditing(false);
      setShowAddOrEditModal(false);
    }
  }, [isRequestCompleted]);

  React.useEffect(() => {
    dispatch(UserActions.setSelectedMenu(menu.PLACES));

    dispatch(PlaceActions.getPlaces());
  }, []);

  const getUsers = () => {
    dispatch(UserActions.getUsers());
  };

  const onCancelAddOrEdit = () => {
    cleanInputs();
    setIsEditing(false);
    setIsEditingField(false);
    setShowAddOrEditModal(false);
  };

  const onAddOrEditPlace = () => {
    if (hasAdminPermission) {
      if (!name) {
        toast.error(t('typePlaceName'));

        return;
      }

      if (!address) {
        toast.error(t('typeAddress'));

        return;
      }

      if (!neighborhood) {
        toast.error(t('typeNeighborhood'));

        return;
      }

      if (!city) {
        toast.error(t('typeCity'));

        return;
      }

      if (!state) {
        toast.error(t('typeState'));

        return;
      }

      if (state.length < 2) {
        toast.error(t('invalidState'));

        return;
      }

      if (!zipCode) {
        toast.error(t('typeZipCode'));

        return;
      }

      if (zipCode.length < 9) {
        toast.error(t('invalidZipCode'));

        return;
      }

      if (!bucketName) {
        toast.error(t('typeBucketName'));

        return;
      }

      if (!fields.length) {
        toast.error(t('insertAtLeastOneField'));

        return;
      }

      const data = {
        fields,
        sports,
        place: {
          city,
          name,
          notes,
          state,
          address,
          zipCode,
          bucketName,
          complement,
          temlanceId,
          tvFtpFolder,
          neighborhood,
          addressNumber,
          isPrivate: selectedPlaceType === placeType.PRIVATE,
        },
      };

      const editData = {
        fields,
        owners,
        sports,
        id: placeId,
        place: {
          city,
          name,
          notes,
          state,
          address,
          zipCode,
          activated,
          bucketName,
          complement,
          temlanceId,
          tvFtpFolder,
          neighborhood,
          addressNumber,
          isPrivate: selectedPlaceType === placeType.PRIVATE,
        },
      };

      if (isEditing) {
        dispatch(PlaceActions.updatePlace(editData));
      } else {
        dispatch(PlaceActions.addPlace(data));
      }
    } else {
      onCancelAddOrEdit();
    }
  };

  const handleAddPlace = () => {
    if (hasAdminPermission) {
      setShowAddOrEditModal(true);
    } else {
      toast.error(t('noPermission'));
    }
  };

  const handleEditPlace = (place) => {
    setIsEditing(true);

    if (users && !users.length && place.isPrivate) {
      getUsers();
    }

    window.scrollTo(0, 0);

    setUserIndex(null);
    setName(place.name);
    setCity(place.city);
    setPlaceId(place.id);
    setNotes(place.notes);
    setState(place.state);
    setFields(place.fields);
    setOwners(place.owners);
    setSports(place.sports);
    setAddress(place.address);
    setZipCode(place.zipCode);
    setActivated(place.activated);
    setIsPrivate(place.isPrivate);
    setTemlanceId(place.temlanceId);
    setBucketName(place.bucketName);
    setComplement(place.complement);
    setTvFtpFolder(place.tvFtpFolder);
    setNeighborhood(place.neighborhood);
    setAddressNumber(place.addressNumber);
    setSelectedPlaceType(
      place.isPrivate ? placeType.PRIVATE : placeType.PUBLIC,
    );

    setShowAddOrEditModal(true);
  };

  const handleSelectedSports = (sport) => {
    const alreadySelected = sports.find((item) => item.name === sport.name);

    if (alreadySelected) {
      const filtered = sports.filter((item) => item.name !== sport.name);
      setSports(filtered);
    } else {
      setSports([...sports, sport]);
    }
  };

  const onAddOwner = () => {
    if (userIndex !== null) {
      const selectedUserUid = users[userIndex].uid;

      const alreadyAdded = owners.some(
        (owner) => owner.uid === selectedUserUid,
      );

      if (alreadyAdded) {
        toast.error(t('userAlreadyAdded'));
      } else {
        setOwners([...owners, users[userIndex]]);
        setUserIndex(null);
      }
    } else {
      toast.error(t('chooseAUser'));
    }
  };

  const onDeleteOwner = (owner) => {
    const filtered = owners.filter((item) => item.uid !== owner.uid);

    setOwners(filtered);
  };

  const onAddField = () => {
    if (fieldName && searchName) {
      const alreadyAdded = fields.find(
        (field) => field.searchName === searchName,
      );

      if (alreadyAdded) {
        toast.error(t('fieldAlreadyAdded'));
      } else {
        if (isEditing) {
          setFields([...fields, { id: 0, fieldName, searchName }]);
        } else {
          setFields([...fields, { fieldName, searchName }]);
        }

        setFieldName('');
        setSearchName('');
      }
    } else {
      toast.error(t('typeFieldName'));
    }
  };

  const handleConfirmEditField = () => {
    selectedField.fieldName = fieldName;
    selectedField.searchName = searchName;

    setIsEditingField(false);
    setFieldName('');
    setSearchName('');
  };

  const handleCancelEditField = () => {
    setIsEditingField(false);
    setFieldName('');
    setSearchName('');
  };

  const handleEditField = (field) => {
    setIsEditingField(true);
    setSelectedField(field);
    setFieldName(field.fieldName);
    setSearchName(field.searchName);
  };

  const handleDeleteField = (field) => {
    const filtered = fields.filter(
      (item) => item.searchName !== field.searchName,
    );

    setFields(filtered);
  };

  const onDeleteFieldClick = async (field) => {
    try {
      await dispatch(PlaceActions.deleteField(field.id));
      handleDeleteField(field);
    } catch {
      // do nothing
    }
  };

  const handleGetPrivateUsers = async () => {
    try {
      await dispatch(PlaceActions.getPrivateUsers(placeId));
      setShowPrivateUsersModal(true);
    } catch {
      // do nothing
    }
  };

  const handleUploadCSV = async (csv) => {
    try {
      const formData = new FormData();

      formData.append('file', csv);

      await dispatch(
        PlaceActions.addPrivateUsersByCSV({
          placeId,
          formData,
        }),
      );

      handleGetPrivateUsers();
    } catch {
      // do nothing
    }
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file) {
      handleUploadCSV(file);
    }
  };

  const onDropRejected = React.useCallback((rejectedFiles) => {
    const { file } = rejectedFiles[0];

    if (file.size > fileMaxSize) {
      toast.error(t('fileTooLarge'));
    } else {
      toast.error(t('somethingWrongOnUpload'));
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    maxFiles: 1,
    accept: '.csv',
    maxSize: fileMaxSize,
  });

  const onClosePrivateUsersModal = () => {
    setUsername('');
    setShowPrivateUsersModal(false);
  };

  const handleUsernameSubmit = async (e) => {
    e.preventDefault();

    if (username.length < 6) {
      toast.error(t('typeAtLeast6Char'));

      return;
    }

    try {
      await dispatch(PlaceActions.addPrivateUser({ placeId, username }));
      setUsername('');
    } catch {
      // do nothing
    }
  };

  const handleDeletePrivateUser = (clickedUsername) => {
    dispatch(
      PlaceActions.deletePrivateUser({
        placeId,
        username: clickedUsername,
      }),
    );
  };

  return (
    <LoggedArea>
      <S.Container>
        {isLoading ? (
          <Loading />
        ) : (
          <S.Widget ref={widgetRef}>
            <S.WidgetHeader>
              <S.PlaceTitleWrapper>
                <S.TableTitle>{t('place')}</S.TableTitle>
              </S.PlaceTitleWrapper>

              <S.CityTitleWrapper>
                <S.TableTitle>{t('city')}</S.TableTitle>
              </S.CityTitleWrapper>

              <S.IdTitleWrapper>
                <S.TableTitle>ID</S.TableTitle>
              </S.IdTitleWrapper>

              <S.IdTitleWrapper>
                <S.TableTitle>Monitor</S.TableTitle>
              </S.IdTitleWrapper>

              <S.ButtonAddWrapper>
                <S.AddButton onClick={() => handleAddPlace()}>
                  {t('add')}
                </S.AddButton>
              </S.ButtonAddWrapper>
            </S.WidgetHeader>

            {places
              ? places
                .sort((x, y) => y.activated - x.activated)
                .map((place) => (
                  <S.Place
                    key={String(place.id)}
                    activated={place.activated}
                    onClick={() => handleEditPlace(place)}
                  >
                    <S.Wrapper>
                      <S.Name>{place.name}</S.Name>
                      <S.Address>
                        {`${place.address}, ${place.addressNumber} - ${place.neighborhood}`}
                      </S.Address>
                    </S.Wrapper>

                    <S.CityWrapper>
                      <S.Name>{`${place.city}/${place.state}`}</S.Name>
                    </S.CityWrapper>

                    <S.IdWrapper>
                      <S.Name>{place.temlanceId}</S.Name>
                    </S.IdWrapper>

                    <S.IdWrapper>
                      <S.Name>{place.tvFtpFolder ? t('yes') : ''}</S.Name>
                    </S.IdWrapper>

                    <S.IconsWrapper>
                      <Tooltip title={t(place.status)}>
                        <S.CamIcon status={place.status} />
                      </Tooltip>

                      <Tooltip title={t('edit')}>
                        <S.Edit />
                      </Tooltip>
                    </S.IconsWrapper>
                  </S.Place>
                ))
              : null}

            {widgetRef && widgetRef.current ? (
              <S.Modal
                isOpen={showAddOrEditModal}
                shouldCloseOnOverlayClick={false}
                parentSelector={() => widgetRef.current}
                onRequestClose={() => onCancelAddOrEdit()}
              >
                <S.ModalContainer>
                  <S.ModalFirstSection>
                    <S.NameInputWrapper>
                      <S.Label>{t('name')}</S.Label>
                      <S.Input
                        value={name}
                        maxLength={64}
                        disabled={!hasAdminPermission}
                        placeholder={t('typePlaceName')}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </S.NameInputWrapper>

                    <S.AddressInputWrapper>
                      <S.Label>{t('address')}</S.Label>
                      <S.Input
                        maxLength={64}
                        value={address}
                        disabled={!hasAdminPermission}
                        placeholder={t('typeAddress')}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </S.AddressInputWrapper>

                    <S.AddressNumberInputWrapper>
                      <S.Label>{t('number')}</S.Label>
                      <S.Input
                        maxLength={8}
                        value={addressNumber}
                        placeholder={t('number')}
                        disabled={!hasAdminPermission}
                        onChange={(e) => {
                          setAddressNumber(onlyDigits(e.target.value));
                        }}
                      />
                    </S.AddressNumberInputWrapper>

                    <S.ComplementInputWrapper>
                      <S.Label>{t('complement')}</S.Label>
                      <S.Input
                        maxLength={32}
                        value={complement}
                        disabled={!hasAdminPermission}
                        placeholder={t('typeComplement')}
                        onChange={(e) => setComplement(e.target.value)}
                      />
                    </S.ComplementInputWrapper>
                  </S.ModalFirstSection>

                  <S.ModalSecondSection>
                    <S.NameInputWrapper>
                      <S.Label>{t('neighborhood')}</S.Label>
                      <S.Input
                        maxLength={64}
                        value={neighborhood}
                        disabled={!hasAdminPermission}
                        placeholder={t('typeNeighborhood')}
                        onChange={(e) => setNeighborhood(e.target.value)}
                      />
                    </S.NameInputWrapper>

                    <S.AddressInputWrapper>
                      <S.Label>{t('city')}</S.Label>
                      <S.Input
                        value={city}
                        maxLength={64}
                        placeholder={t('typeCity')}
                        disabled={!hasAdminPermission}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </S.AddressInputWrapper>

                    <S.AddressNumberInputWrapper>
                      <S.Label>{t('uf')}</S.Label>
                      <S.Input
                        value={state}
                        maxLength={2}
                        placeholder={t('uf')}
                        disabled={!hasAdminPermission}
                        onChange={(e) => setState(e.target.value.toUpperCase())}
                      />
                    </S.AddressNumberInputWrapper>

                    <S.ComplementInputWrapper>
                      <S.Label>{t('zipCode')}</S.Label>
                      <S.Input
                        maxLength={9}
                        value={zipCode}
                        disabled={!hasAdminPermission}
                        placeholder={t('typeZipCode')}
                        onChange={(e) => {
                          setZipCode(zipCodeMask(e.target.value));
                        }}
                      />
                    </S.ComplementInputWrapper>
                  </S.ModalSecondSection>

                  <S.ModalSecondSection>
                    <S.AddressNumberInputWrapper>
                      <S.Label>ID</S.Label>

                      <S.Input
                        maxLength={3}
                        placeholder="ID"
                        value={temlanceId}
                        disabled={!hasAdminPermission}
                        onChange={(e) => setTemlanceId(e.target.value)}
                      />
                    </S.AddressNumberInputWrapper>

                    <S.NameInputWrapper>
                      <S.Label>{`${t('bucket')}*`}</S.Label>

                      <S.Input
                        maxLength={32}
                        value={bucketName}
                        disabled={!hasAdminPermission}
                        placeholder={t('typeBucketName')}
                        onChange={(e) => {
                          setBucketName(e.target.value.toUpperCase().trim());
                        }}
                      />
                    </S.NameInputWrapper>

                    <S.NameInputWrapper>
                      <S.Label>{t('ftp')}</S.Label>
                      <S.Input
                        maxLength={32}
                        value={tvFtpFolder}
                        disabled={!hasAdminPermission}
                        placeholder={t('typeFTPFolder')}
                        onChange={(e) => setTvFtpFolder(e.target.value)}
                      />
                    </S.NameInputWrapper>

                    <S.ObservationWrapper>
                      <S.Observation>
                        {`*${t('bucketObservation')}`}
                      </S.Observation>
                    </S.ObservationWrapper>
                  </S.ModalSecondSection>

                  <S.ModalSportsContainer>
                    <S.Label>{`${t('mainSports')}:`}</S.Label>

                    <S.ModalSportsWrapper>
                      {sportsList
                        ? sportsList.map((sport) => (
                          <S.CheckboxWrapper
                            key={sport.value}
                            onClick={
                                hasAdminPermission
                                  ? () => {
                                    handleSelectedSports({
                                      name: sport.value,
                                    });
                                  }
                                  : () => null
                              }
                          >
                            <S.Checkbox
                              checked={sports.some(
                                (item) => item.name === sport.value,
                              )}
                            />
                            <S.CheckboxLabel>{sport.name}</S.CheckboxLabel>
                          </S.CheckboxWrapper>
                        ))
                        : null}
                    </S.ModalSportsWrapper>
                  </S.ModalSportsContainer>

                  <S.ModalSportsContainer>
                    <S.Label>{`${t('type')}:`}</S.Label>

                    <S.RowCenterBtw>
                      <S.RadioContainer>
                        <S.RadioWrapper
                          onClick={
                            hasAdminPermission
                              ? () => setSelectedPlaceType(placeType.PUBLIC)
                              : () => null
                          }
                        >
                          <S.RadioButton
                            selected={selectedPlaceType === placeType.PUBLIC}
                          >
                            {selectedPlaceType === placeType.PUBLIC ? (
                              <S.RadioSelected />
                            ) : null}
                          </S.RadioButton>

                          <S.RadioLabel>{t('public')}</S.RadioLabel>
                        </S.RadioWrapper>

                        <S.RadioWrapper
                          onClick={
                            hasAdminPermission
                              ? () => setSelectedPlaceType(placeType.PRIVATE)
                              : () => null
                          }
                        >
                          <S.RadioButton
                            selected={selectedPlaceType === placeType.PRIVATE}
                          >
                            {selectedPlaceType === placeType.PRIVATE ? (
                              <S.RadioSelected />
                            ) : null}
                          </S.RadioButton>

                          <S.RadioLabel>{t('private')}</S.RadioLabel>
                        </S.RadioWrapper>

                        {isPrivate
                        && (hasAdminPermission || hasOwnerPermission) ? (
                          <S.Dropzone {...getRootProps()}>
                            <S.DropzoneInput {...getInputProps()} />
                            {isUploading ? (
                              <Loading
                                type="spin"
                                width={20}
                                height={20}
                                color="#000"
                              />
                            ) : (
                              `${t('upload')} CSV`
                            )}
                          </S.Dropzone>
                          ) : null}
                      </S.RadioContainer>

                      {isPrivate ? (
                        <S.ParticipantsButton
                          onClick={() => handleGetPrivateUsers()}
                        >
                          {isGettingUsers ? (
                            <Loading
                              type="spin"
                              width={20}
                              height={20}
                              color="#000"
                            />
                          ) : (
                            t('participants')
                          )}
                        </S.ParticipantsButton>
                      ) : null}
                    </S.RowCenterBtw>

                    {isPrivate && hasAdminPermission ? (
                      <S.OwnerWrapper>
                        <S.Label>{`${t('owner')}:`}</S.Label>

                        <S.AntDSelect
                          bordered={false}
                          placeholder={t('chooseAUser')}
                          onChange={(value) => setUserIndex(value)}
                          value={
                            userIndex !== null ? users[userIndex].name : null
                          }
                        >
                          {users.map((user, index) => (
                            <S.AntDOption key={user.uid} value={index}>
                              {user.name}
                            </S.AntDOption>
                          ))}
                        </S.AntDSelect>

                        <S.AddFieldButton onClick={() => onAddOwner()}>
                          {t('add')}
                        </S.AddFieldButton>
                      </S.OwnerWrapper>
                    ) : null}

                    {owners.length > 0 && hasAdminPermission ? (
                      <S.FieldsContainer>
                        {owners.length === 1
                          ? owners.map((owner) => (
                            <S.SingleFieldCard key={owner.uid}>
                              <S.FieldTextsWrapper>
                                <S.Name>{owner.name}</S.Name>
                                <S.Address>{owner.email}</S.Address>
                              </S.FieldTextsWrapper>

                              {hasAdminPermission ? (
                                <S.Trash
                                  onClick={() => onDeleteOwner(owner)}
                                />
                              ) : null}
                            </S.SingleFieldCard>
                          ))
                          : owners.map((owner) => (
                            <S.FieldCard key={owner.uid}>
                              <S.FieldTextsWrapper>
                                <S.Name>{owner.name}</S.Name>
                                <S.Address>{owner.email}</S.Address>
                              </S.FieldTextsWrapper>

                              {hasAdminPermission ? (
                                <S.Trash
                                  onClick={() => onDeleteOwner(owner)}
                                />
                              ) : null}
                            </S.FieldCard>
                          ))}
                      </S.FieldsContainer>
                    ) : null}
                  </S.ModalSportsContainer>

                  <S.ModalSportsContainer>
                    <S.FieldsLabel>{`${t('fields')}:`}</S.FieldsLabel>

                    {hasAdminPermission ? (
                      <S.ModalFirstSection>
                        <S.NameInputWrapper>
                          <S.Label>{t('fieldName')}</S.Label>
                          <S.Input
                            maxLength={32}
                            value={fieldName}
                            disabled={!hasAdminPermission}
                            placeholder={t('nameWillBeDisplayed')}
                            onChange={(e) => setFieldName(e.target.value)}
                          />
                        </S.NameInputWrapper>

                        <S.NameInputWrapper>
                          <S.Label>{`${t('registeredName')}*`}</S.Label>
                          <S.Input
                            maxLength={32}
                            value={searchName}
                            disabled={!hasAdminPermission}
                            placeholder={t('nameWasRegistered')}
                            onChange={(e) => {
                              setSearchName(
                                e.target.value.toUpperCase().trim(),
                              );
                            }}
                          />
                        </S.NameInputWrapper>

                        {isEditingField ? (
                          <S.CancelConfirmWrapper>
                            <S.CancelIcon
                              onClick={() => handleCancelEditField()}
                            />
                            <S.ConfirmIcon
                              onClick={() => handleConfirmEditField()}
                            />
                          </S.CancelConfirmWrapper>
                        ) : null}

                        {isEditingField ? null : (
                          <S.AddFieldButtonWrapper>
                            <S.AddFieldButton onClick={() => onAddField()}>
                              {t('addField')}
                            </S.AddFieldButton>
                          </S.AddFieldButtonWrapper>
                        )}
                      </S.ModalFirstSection>
                    ) : null}

                    {fields.length > 0 ? (
                      <S.FieldsContainer>
                        {fields.length === 1
                          ? fields.map((field) => (
                            <Tooltip
                              title={
                                  field.lastVideoRecordedAt
                                    ? format(
                                      parseISO(field.lastVideoRecordedAt),
                                      "dd/MM/yy 'às' HH:mm'h'",
                                    )
                                    : null
                                }
                            >
                              <S.SingleFieldCard
                                key={field.searchName}
                                status={field.status}
                              >
                                <S.FieldTextsWrapper>
                                  <S.Name>{field.fieldName}</S.Name>
                                  <S.Address>{field.searchName}</S.Address>
                                </S.FieldTextsWrapper>

                                {isEditing ? (
                                  isDeletingField.includes(field.id) ? (
                                    <Loading
                                      type="spin"
                                      width={20}
                                      height={20}
                                      color="#f5f5f5"
                                    />
                                  ) : (
                                    <div>
                                      <S.Edit
                                        onClick={() => handleEditField(field)}
                                      />

                                      <S.Trash
                                        onClick={() => onDeleteFieldClick(field)}
                                      />
                                    </div>
                                  )
                                ) : (
                                  <S.Trash
                                    onClick={() => handleDeleteField(field)}
                                  />
                                )}
                              </S.SingleFieldCard>
                            </Tooltip>
                          ))
                          : fields.map((field) => (
                            <Tooltip
                              title={
                                  field.lastVideoRecordedAt
                                    ? format(
                                      parseISO(field.lastVideoRecordedAt),
                                      "dd/MM/yy 'às' HH:mm'h'",
                                    )
                                    : null
                                }
                            >
                              <S.FieldCard
                                key={field.searchName}
                                status={field.status}
                              >
                                <S.FieldTextsWrapper>
                                  <S.Name>{field.fieldName}</S.Name>
                                  <S.Address>{field.searchName}</S.Address>
                                </S.FieldTextsWrapper>

                                {hasAdminPermission ? (
                                  <>
                                    {isEditing ? (
                                      isDeletingField.includes(field.id) ? (
                                        <Loading
                                          type="spin"
                                          width={20}
                                          height={20}
                                          color="#f5f5f5"
                                        />
                                      ) : (
                                        <div>
                                          <S.Edit
                                            onClick={() => handleEditField(field)}
                                          />

                                          <S.Trash
                                            onClick={() => onDeleteFieldClick(field)}
                                          />
                                        </div>
                                      )
                                    ) : (
                                      <S.Trash
                                        onClick={() => handleDeleteField(field)}
                                      />
                                    )}
                                  </>
                                ) : null}
                              </S.FieldCard>
                            </Tooltip>
                          ))}
                      </S.FieldsContainer>
                    ) : null}
                  </S.ModalSportsContainer>

                  <S.ObservationContainer>
                    <S.TextAreaWrapper>
                      <S.Label>{`${t('observations')}:`}</S.Label>
                      <S.TextArea
                        value={notes}
                        maxLength={1000}
                        disabled={!hasAdminPermission}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </S.TextAreaWrapper>
                  </S.ObservationContainer>

                  {isEditing ? (
                    <S.ModalEditFooter>
                      <S.CheckboxWrapper
                        onClick={
                          hasAdminPermission
                            ? () => setActivated(!activated)
                            : () => null
                        }
                      >
                        <S.Checkbox checked={activated} />
                        <S.CheckboxLabel>{t('active')}</S.CheckboxLabel>
                      </S.CheckboxWrapper>

                      <S.ButtonsWrapper>
                        <S.Cancel onClick={() => onCancelAddOrEdit()}>
                          {t('cancel')}
                        </S.Cancel>

                        <S.ConfirmButton onClick={() => onAddOrEditPlace()}>
                          {isConfirmLoading ? (
                            <Loading
                              type="spin"
                              width={20}
                              height={20}
                              color="#000"
                            />
                          ) : (
                            t('confirm')
                          )}
                        </S.ConfirmButton>
                      </S.ButtonsWrapper>
                    </S.ModalEditFooter>
                  ) : (
                    <S.ModalFooter>
                      <S.ButtonsWrapper>
                        <S.Cancel onClick={() => onCancelAddOrEdit()}>
                          {t('cancel')}
                        </S.Cancel>

                        <S.ConfirmButton onClick={() => onAddOrEditPlace()}>
                          {isConfirmLoading ? (
                            <Loading
                              type="spin"
                              width={20}
                              height={20}
                              color="#000"
                            />
                          ) : (
                            t('confirm')
                          )}
                        </S.ConfirmButton>
                      </S.ButtonsWrapper>
                    </S.ModalFooter>
                  )}
                </S.ModalContainer>
              </S.Modal>
            ) : null}

            {widgetRef && widgetRef.current ? (
              <S.Modal
                isOpen={showPrivateUsersModal}
                shouldCloseOnOverlayClick={false}
                parentSelector={() => widgetRef.current}
                onRequestClose={() => onClosePrivateUsersModal()}
              >
                <S.ModalPrivateUsersContainer>
                  {hasAdminPermission || hasOwnerPermission ? (
                    <S.UsernameForm onSubmit={handleUsernameSubmit}>
                      <S.UsernameInputWrapper>
                        <S.Label>Username</S.Label>
                        <S.Input
                          maxLength={20}
                          value={username}
                          placeholder={t('userName')}
                          onChange={(e) => {
                            setUsername(
                              usernameMask(e.target.value.toLowerCase()),
                            );
                          }}
                        />
                      </S.UsernameInputWrapper>

                      <S.AddPrivateUserButton type="submit">
                        {isAddingUser ? (
                          <Loading
                            type="spin"
                            width={20}
                            height={20}
                            color="#000"
                          />
                        ) : (
                          t('add')
                        )}
                      </S.AddPrivateUserButton>
                    </S.UsernameForm>
                  ) : null}

                  <S.UsernamesContainer>
                    {privateUsers && privateUsers.length > 0 ? (
                      privateUsers.map((user) => (
                        <S.UsernameCard key={user.uid}>
                          <S.FieldTextsWrapper>
                            <S.Name>{user.username}</S.Name>
                            <S.UsernameCardDescription>
                              {user.name || t('nameNotRegistered')}
                            </S.UsernameCardDescription>
                          </S.FieldTextsWrapper>

                          {hasAdminPermission || hasOwnerPermission ? (
                            <>
                              {isDeletingUser.includes(user.username) ? (
                                <Loading
                                  type="spin"
                                  width={20}
                                  height={20}
                                  color="#f5f5f5"
                                />
                              ) : (
                                <S.Trash
                                  onClick={() => {
                                    handleDeletePrivateUser(user.username);
                                  }}
                                />
                              )}
                            </>
                          ) : null}
                        </S.UsernameCard>
                      ))
                    ) : (
                      <S.EmptyStateText>{t('noPrivateUsers')}</S.EmptyStateText>
                    )}
                  </S.UsernamesContainer>

                  <S.CloseButton onClick={() => onClosePrivateUsersModal()}>
                    <S.CloseIcon />
                  </S.CloseButton>
                </S.ModalPrivateUsersContainer>
              </S.Modal>
            ) : null}
          </S.Widget>
        )}
      </S.Container>
    </LoggedArea>
  );
};

export default PlacesPage;
