import { ComplaintActions } from '../actions';

const initialState = {
  errorMessage: '',
  isLoading: false,
  isGetByIdLoading: [],
  isConfirmLoading: false,
  isRequestCompleted: false,

  videosComplaints: [],
  commentsComplaints: [],
  profilesComplaints: [],

  videoComplaint: {},
  commentComplaint: {},
  profileComplaint: {},

  isVideoRequestedSuccessfully: false,
  isCommentRequestedSuccessfully: false,
  isProfileRequestedSuccessfully: false,
};

const place = (state = initialState, action) => {
  switch (action.type) {
    case ComplaintActions.GET_VIDEOS_COMPLAINTS:
      return {
        ...state,
        videosComplaints: action.payload,
      };
    case ComplaintActions.GET_COMMENTS_COMPLAINTS:
      return {
        ...state,
        commentsComplaints: action.payload,
      };
    case ComplaintActions.GET_PROFILES_COMPLAINTS:
      return {
        ...state,
        profilesComplaints: action.payload,
      };
    case ComplaintActions.GET_VIDEO_COMPLAINT_BY_ID:
      return {
        ...state,
        videoComplaint: action.payload,
      };
    case ComplaintActions.GET_COMMENT_COMPLAINT_BY_ID:
      return {
        ...state,
        commentComplaint: action.payload,
      };
    case ComplaintActions.GET_PROFILE_COMPLAINT_BY_ID:
      return {
        ...state,
        profileComplaint: action.payload,
      };
    case ComplaintActions.IS_CONFIRM_LOADING:
      return {
        ...state,
        isConfirmLoading: action.payload,
      };
    case ComplaintActions.IS_GET_BY_ID_LOADING:
      return {
        ...state,
        isGetByIdLoading: action.payload,
      };
    case ComplaintActions.IS_REQUEST_COMPLETED:
      return {
        ...state,
        isRequestCompleted: action.payload,
      };
    case ComplaintActions.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ComplaintActions.REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case ComplaintActions.IS_VIDEO_REQUESTED_SUCCESSFULLY:
      return {
        ...state,
        isVideoRequestedSuccessfully: action.payload,
      };
    case ComplaintActions.IS_COMMENT_REQUESTED_SUCCESSFULLY:
      return {
        ...state,
        isCommentRequestedSuccessfully: action.payload,
      };
    case ComplaintActions.IS_PROFILE_REQUESTED_SUCCESSFULLY:
      return {
        ...state,
        isProfileRequestedSuccessfully: action.payload,
      };
    default:
      return state;
  }
};

export default place;
