export const alphanumericWithAccents = (text) => (
  text.replace(/^[\s]|[^a-zA-Z0-9\sàáâãÀÁÂÃçÇéêÉÊíÍóôõÓÔÕúÚ]/g, '')
);

export const customLetters = (text) => (
  text.replace(
    /^[\s]|[^a-zA-Z0-9\sàáâãÀÁÂÃçÇéêÉÊíÍóôõÓÔÕúÚ!@#$%&*()=+-/.;:,?_]/g, '',
  )
);

export const usernameMask = (text) => text.replace(/\W/g, '');

export const onlyDigits = (text) => text.replace(/\D/g, '');

export const onlyEmail = (text) => text.replace(/[^a-z0-9@+._-]/g, '');

export const zipCodeMask = (text) => {
  text = text.replace(/\D/g, '');
  text = text.replace(/(\d{5})(\d)/, '$1-$2');

  return text;
};

export const replaceDiacritics = (str) => {
  let string = str;

  const diacritics = [
    { char: 'A', base: /[\300-\306]/g },
    { char: 'a', base: /[\340-\346]/g },
    { char: 'E', base: /[\310-\313]/g },
    { char: 'e', base: /[\350-\353]/g },
    { char: 'I', base: /[\314-\317]/g },
    { char: 'i', base: /[\354-\357]/g },
    { char: 'O', base: /[\322-\330]/g },
    { char: 'o', base: /[\362-\370]/g },
    { char: 'U', base: /[\331-\334]/g },
    { char: 'u', base: /[\371-\374]/g },
    { char: 'N', base: /[\321]/g },
    { char: 'n', base: /[\361]/g },
    { char: 'C', base: /[\307]/g },
    { char: 'c', base: /[\347]/g },
  ];

  diacritics.forEach((letter) => {
    string = string.replace(letter.base, letter.char);
  });

  return string;
};
