import firebase from 'firebase/app';

import Storage, { keys } from '../../data/localStorage';

export const onLogin = () => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      Storage.store(keys.UID, user.uid);
      Storage.store(keys.TOKEN, user.za);
      Storage.store(keys.EMAIL, user.email);

      window.location = '/';
    } else {
      window.location = '/login';
    }
  });
};

export const onLogout = () => {
  firebase.auth().signOut();
  Storage.clear();
  window.location = '/login';
};
