import api from './api';

export const getUsers = async () => {
  const response = await api.get('/admin-users');

  return (response && response.data) || {};
};

export const addUser = async (user) => {
  const response = await api.post('/admin-users', user);

  return (response && response.data) || {};
};

export const updateUser = async (user) => {
  const response = await api.put(`/admin-users/${user.uid}`, user);

  return (response && response.data) || {};
};

export const updateUserPermissions = async (user) => {
  const response = await api.post(`/admin-users/${user.uid}/permissions`, user);

  return (response && response.data) || {};
};

export const deleteUser = async (uid) => {
  const response = await api.delete(`/admin-users/${uid}`);

  return (response && response.data) || {};
};

export const getMinePermissions = async (uid) => {
  const response = await api.get(`/admin-users/${uid}/permissions`);

  return (response && response.data) || {};
};
