import i18n from 'i18next';

import { UserServices } from '../../services';

import Feature from '../../data/enums/feature';
import { typeError } from '../../data/enums/error';

export const IS_LOADING = 'IS_LOADING:user';
export const REQUEST_ERROR = 'REQUEST_ERROR:user';
export const IS_CONFIRM_LOADING = 'IS_CONFIRM_LOADING:user';
export const IS_REQUEST_COMPLETED = 'IS_REQUEST_COMPLETED:user';

export const GET_USERS = 'GET_USERS:user';
export const SET_SELECTED_MENU = 'SET_SELECTED_MENU:user';
export const GET_MINE_PERMISSIONS = 'GET_MINE_PERMISSIONS:user';

export const SET_ADS_PERMISSIONS = 'SET_ADS_PERMISSIONS:user';
export const SET_USERS_PERMISSIONS = 'SET_USERS_PERMISSIONS:user';
export const SET_PLACES_PERMISSIONS = 'SET_PLACES_PERMISSIONS:user';
export const SET_TV_VIDEOS_PERMISSIONS = 'SET_TV_VIDEOS_PERMISSIONS:user';
export const SET_COMPLAINTS_PERMISSIONS = 'SET_COMPLAINTS_PERMISSIONS:user';
export const SET_INDICATORS_PERMISSIONS = 'SET_INDICATORS_PERMISSIONS:user';

const startRequest = () => (dispatch) => {
  dispatch({ type: IS_CONFIRM_LOADING, payload: true });
  dispatch({ type: IS_REQUEST_COMPLETED, payload: false });
};

const endRequest = () => (dispatch) => {
  dispatch({ type: REQUEST_ERROR, payload: '' });
  dispatch({ type: IS_CONFIRM_LOADING, payload: false });
  dispatch({ type: IS_REQUEST_COMPLETED, payload: true });
};

export const setSelectedMenu = (menu) => (dispatch) => {
  dispatch({ type: SET_SELECTED_MENU, payload: menu });
};

export const getUsers = () => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    const { users } = await UserServices.getUsers();

    dispatch({ type: GET_USERS, payload: users });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
  }
};

export const addUser = (data) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await UserServices.addUser(data);
    dispatch(getUsers());
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};

export const updateUser = (data) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await UserServices.updateUser(data);
    dispatch(getUsers());
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};

export const updateUserPermissions = (data) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await UserServices.updateUserPermissions(data);
    dispatch(getUsers());
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};

export const deleteUser = (uid) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await UserServices.deleteUser(uid);
    dispatch(getUsers());
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};

export const getMinePermissions = (uid) => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    const { permissions } = await UserServices.getMinePermissions(uid);

    dispatch({ type: GET_MINE_PERMISSIONS, payload: permissions });
    dispatch({
      type: SET_ADS_PERMISSIONS,
      payload: permissions.some(({ feature }) => feature === Feature.ADS),
    });
    dispatch({
      type: SET_USERS_PERMISSIONS,
      payload: permissions.some(({ feature }) => feature === Feature.USERS),
    });
    dispatch({
      type: SET_PLACES_PERMISSIONS,
      payload: permissions.some(({ feature }) => feature === Feature.PLACES),
    });
    dispatch({
      type: SET_TV_VIDEOS_PERMISSIONS,
      payload: permissions.some(({ feature }) => feature === Feature.TV_VIDEOS),
    });
    dispatch({
      type: SET_COMPLAINTS_PERMISSIONS,
      payload: permissions.some(({ feature }) => feature === Feature.COMPLAINTS),
    });
    dispatch({
      type: SET_INDICATORS_PERMISSIONS,
      payload: permissions.some(({ feature }) => feature === Feature.INDICATORS),
    });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: IS_LOADING, payload: false });
    dispatch({ type: REQUEST_ERROR, payload: '' });
  }
};
