import React from 'react';
import styled from 'styled-components';

import ReactModal from 'react-modal';

const ReactModalAdapter = ({ className, modalClassName, ...props }) => (
  <ReactModal
    className={modalClassName}
    portalClassName={className}
    bodyOpenClassName="portalOpen"
    {...props}
  />
);

export const Modal = styled(ReactModalAdapter).attrs({
  modalClassName: 'Modal',
  overlayClassName: 'Overlay',
})`
  & .Overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  & .Modal {
    display: flex;
    flex: 1;
    flex-direction: column;

    width: 24rem;
    height: 30rem;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid var(--color-form-border);
    background: var(--color-form-background);

    outline: none;

    position: absolute;
    top: 6rem;
    left: 98%;
    transform: translate(-98%);
  }
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 6rem;
  height: 6rem;
  margin: 1rem;
  border-radius: 50%;
  background: var(--color-primary);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 4rem;
  font-family: Bebas Neue;
  text-transform: uppercase;
  color: var(--color-black);
`;

export const Name = styled.p`
  width: 100%;
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1rem;
  overflow-wrap: anywhere;
  color: var(--color-white);
`;

export const Email = styled(Name)`
  font-size: 1.4rem;
  color: #ccc;
`;

export const Footer = styled.footer``;
