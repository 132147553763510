import i18n from 'i18next';

import { PlaceServices } from '../../services';

import { typeError } from '../../data/enums/error';

export const IS_LOADING = 'IS_LOADING:place';
export const IS_UPLOADING = 'IS_UPLOADING:place';
export const REQUEST_ERROR = 'REQUEST_ERROR:place';
export const IS_ADDING_USER = 'IS_ADDING_USER:place';
export const IS_DELETING_USER = 'IS_DELETING_USER:place';
export const IS_GETTING_USERS = 'IS_GETTING_USERS:place';
export const IS_DELETING_FIELD = 'IS_DELETING_FIELD:place';
export const IS_CONFIRM_LOADING = 'IS_CONFIRM_LOADING:place';
export const IS_REQUEST_COMPLETED = 'IS_REQUEST_COMPLETED:place';

export const GET_PLACES = 'GET_PLACES:place';
export const GET_TV_PLACES = 'GET_TV_PLACES:place';
export const GET_PLACES_NAME = 'GET_PLACES_NAME:place';
export const GET_PRIVATE_USERS = 'GET_PRIVATE_USERS:place';

const startRequest = () => (dispatch) => {
  dispatch({ type: IS_CONFIRM_LOADING, payload: true });
  dispatch({ type: IS_REQUEST_COMPLETED, payload: false });
};

const endRequest = () => (dispatch) => {
  dispatch({ type: REQUEST_ERROR, payload: '' });
  dispatch({ type: IS_CONFIRM_LOADING, payload: false });
  dispatch({ type: IS_REQUEST_COMPLETED, payload: true });
};

export const getPlaces = () => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    const { places } = await PlaceServices.getPlaces();

    dispatch({ type: GET_PLACES, payload: places });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
  }
};

export const getPlacesName = () => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    const { places } = await PlaceServices.getPlacesName();

    dispatch({ type: GET_PLACES_NAME, payload: places });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
  }
};

export const addPlace = (data) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await PlaceServices.addPlace(data);
    dispatch(getPlaces());
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};

export const updatePlace = (data) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await PlaceServices.updatePlace(data);
    dispatch(getPlaces());
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};

export const getTVPlaces = () => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    const { places } = await PlaceServices.getTVPlaces();

    const allPlaces = { id: 0, name: 'Todos' };

    dispatch({ type: GET_TV_PLACES, payload: [allPlaces, ...places] });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
  }
};

export const getPrivateUsers = (placeId) => async (dispatch) => {
  try {
    dispatch({ type: IS_GETTING_USERS, payload: true });

    const { privateUsers } = await PlaceServices.getPrivateUsers(placeId);
    dispatch({ type: GET_PRIVATE_USERS, payload: privateUsers });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });

    throw err;
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_GETTING_USERS, payload: false });
  }
};

export const addPrivateUsersByCSV = (data) => async (dispatch) => {
  try {
    dispatch({ type: IS_UPLOADING, payload: true });

    await PlaceServices.addPrivateUsersByCSV(data);
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });

    throw err;
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_UPLOADING, payload: false });
  }
};

export const addPrivateUser = ({ placeId, username }) => async (dispatch) => {
  try {
    dispatch({ type: IS_ADDING_USER, payload: true });

    await PlaceServices.addPrivateUser({ placeId, username });
    dispatch(getPrivateUsers(placeId));
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });

    throw err;
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_ADDING_USER, payload: false });
  }
};

export const deletePrivateUser = ({ placeId, username }) => async (dispatch) => {
  try {
    dispatch({ type: IS_DELETING_USER, payload: [username] });

    await PlaceServices.deletePrivateUser({ placeId, username });
    dispatch(getPrivateUsers(placeId));
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_DELETING_USER, payload: [] });
  }
};

export const deleteField = (fieldId) => async (dispatch) => {
  try {
    dispatch({ type: IS_DELETING_FIELD, payload: [fieldId] });

    await PlaceServices.deleteField(fieldId);
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });

    throw err;
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_DELETING_FIELD, payload: [] });
  }
};
