import React from 'react';
import styled from 'styled-components';

import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';
import ReactPlayer from 'react-player';
import { Select, DatePicker } from 'antd';

import 'antd/dist/antd.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8rem;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.header`
  width: 60%;
  min-width: 700px;
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
`;

export const AntDSelect = styled(Select)`
  width: 100%;
  margin-right: 2rem;
  height: 3.6rem;
  border-radius: 0.6rem;
  border: 1px solid var(--color-input-border);
  background: var(--color-input-background);
`;

export const AntDOption = styled(Option)``;

export const AntDRangePicker = styled(RangePicker)`
  width: 50%;
  height: 3.6rem;
  border-radius: 0.6rem;
  border: 1px solid var(--color-input-border);
  background: var(--color-input-background);
`;

export const RadioContainer = styled.div`
  width: 59%;
  display: flex;
  margin-bottom: 2rem;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioLabel = styled.label`
  margin-left: 1rem;
  margin-right: 2rem;
  color: var(--color-white);

  cursor: pointer;
`;

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ selected }) => (
    selected ? 'var(--color-primary)' : 'var(--color-white)'
  )};

  cursor: pointer;
`;

export const RadioSelected = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-primary);
`;

export const Widget = styled.div`
  position: relative;
  width: 60%;
  min-width: 700px;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  border-radius: 0.8rem;
  border: 1px solid var(--color-form-border);
  background: var(--color-form-background);
`;

export const WidgetHeader = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 2rem 0.5rem;
  border-bottom: 1px solid var(--color-form-border);
`;

export const WidgetFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.6rem 2rem;
`;

export const TableTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
`;

export const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  margin-bottom: 0.5rem;
  border-radius: 0.4rem;
  font: 400 1.6rem Maven Pro;
  color: var(--color-black);
  background: var(--color-primary);

  :hover {
    background: var(--color-primary-dark);
  }
`;

export const LoadMoreButton = styled(SendButton)`
  margin-bottom: 0;
`;

export const VideoItem = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
  padding: 1rem 2rem;
  background: var(--color-background);
  border-bottom: 1px solid var(--color-form-border);
`;

export const Thumbnail = styled.img`
  height: 8rem;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ThumbnailWrapper = styled(ItemWrapper)`
  cursor: pointer;
`;

export const ItemText = styled.span`
  font-size: 1.6rem;
  color: var(--color-white);
`;

export const Remove = styled(FiX).attrs({
  size: 24,
  color: 'var(--color-red)',
})`
  cursor: pointer;
`;

const ReactModalAdapter = ({ className, modalClassName, ...props }) => (
  <ReactModal
    className={modalClassName}
    portalClassName={className}
    bodyOpenClassName="portalOpen"
    {...props}
  />
);

export const Modal = styled(ReactModalAdapter).attrs({
  modalClassName: 'Modal',
  overlayClassName: 'Overlay',
})`
  & .Overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  & .Modal {
    display: flex;
    flex: 1;

    width: 100%;
    padding: 2rem;
    border-radius: 4px;
    border: 1px solid var(--color-form-border);
    background: var(--color-form-background);

    outline: none;

    position: absolute;
    top: 1%;
  }
`;

export const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Close = styled(FiX).attrs({
  size: 22,
  color: 'var(--color-white)',
})`
  cursor: pointer;
`;

export const VideoPlayer = styled(ReactPlayer)``;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const EmptyStateText = styled.span`
  font-size: 2.2rem;
  color: var(--color-white);
`;
