import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Storage, { keys } from '../data/localStorage';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const token = Storage.find(keys.TOKEN);

  return (
    <Route
      {...rest}
      render={(routeProps) => (token ? (
        <RouteComponent {...routeProps} />
      ) : (
        <Redirect to="/login" />
      ))}
    />
  );
};

export default PrivateRoute;
