import { VideoActions } from '../actions';

const initialState = {
  errorMessage: '',
  isLoading: false,
  isRequestSuccess: false,

  offset: 0,
  tvVideos: [],
};

const tvVideo = (state = initialState, action) => {
  switch (action.type) {
    case VideoActions.GET_TV_VIDEOS:
      return {
        ...state,
        tvVideos: action.payload,
      };
    case VideoActions.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case VideoActions.REQUEST_SUCCESS:
      return {
        ...state,
        isRequestSuccess: action.payload,
      };
    case VideoActions.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case VideoActions.REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default tvVideo;
