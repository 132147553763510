import React from 'react';

import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';

import ptBR from 'antd/lib/locale/pt_BR';

import firebase from 'firebase/app';
import 'firebase/analytics';

import firebaseConfig from './config/firebase';

import AppRouter from './routes/AppRouter';

import store from './redux';

import './App.css';

const App = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  return (
    <>
      <Provider store={store}>
        <ConfigProvider locale={ptBR}>
          <AppRouter />
        </ConfigProvider>
      </Provider>
    </>
  );
};

export default App;
