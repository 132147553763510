const find = (key) => localStorage.getItem(key);

const store = (key, value) => localStorage.setItem(key, value);

const remove = (key) => localStorage.removeItem(key);

const clear = () => localStorage.clear();

export default {
  find,
  store,
  remove,
  clear,
};

export const keys = {
  UID: 'temlance@uid',
  TOKEN: 'temlance@token',
  EMAIL: 'temlance@email',
};
