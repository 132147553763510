import React from 'react';

import 'firebase/auth';
import firebase from 'firebase/app';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AuthButton from '../../components/Button/AuthButton';

import { typeError } from '../../data/enums/error';

import { AuthActions } from '../../redux/actions';

import * as S from './styles';

const Login = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);
      dispatch(AuthActions.onLogin());
    } catch (err) {
      toast.error(typeError.get(err.code) || t('genericError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      await firebase.auth().sendPasswordResetEmail(email);
      toast.success(t('emailSentSuccessfully'));
    } catch (err) {
      toast.error(typeError.get(err.code) || t('genericError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.Container>
      <S.Main>
        <S.Logo />

        <S.LoginForm onSubmit={handleLogin}>
          <S.FormWrapper>
            <S.Label>{t('email')}</S.Label>
            <S.Input
              email
              type="email"
              value={email}
              placeholder={t('typeYourEmail')}
              onChange={(e) => setEmail(e.target.value)}
            />

            <S.RowCenterBtw>
              <S.Label>{t('password')}</S.Label>
              <S.ForgotPassword href="" onClick={handleForgotPassword}>
                {t('forgotPassword')}
              </S.ForgotPassword>
            </S.RowCenterBtw>

            <S.Input
              type="password"
              value={password}
              placeholder={t('typeYourPassword')}
              onChange={(e) => setPassword(e.target.value)}
            />

            <AuthButton isLoading={isLoading}>{t('login')}</AuthButton>
          </S.FormWrapper>
        </S.LoginForm>
      </S.Main>
    </S.Container>
  );
};

export default withRouter(Login);
