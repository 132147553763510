import api from './api';

export const getTVVideos = async (params) => {
  const response = await api.get('/admin/tv/videos', { params });

  return (response && response.data) || {};
};

export const sendVideosForTV = async (payload) => {
  const response = await api.post('/admin/tv/videos', payload, {
    timeout: 120000,
  });

  return (response && response.data) || {};
};
