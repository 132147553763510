import React from 'react';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';

import Content from './Content';

import 'react-toastify/dist/ReactToastify.css';

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <ToastContainer />
    <Content />
  </Router>
);

export default AppRouter;
