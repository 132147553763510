import api from './api';

export const getVideosComplaints = async (params) => {
  const response = await api.get('/admin/complaints/videos', { params });

  return (response && response.data) || {};
};

export const getCommentsComplaints = async (params) => {
  const response = await api.get('/admin/complaints/comments', { params });

  return (response && response.data) || {};
};

export const getProfilesComplaints = async (params) => {
  const response = await api.get('/admin/complaints/profiles', { params });

  return (response && response.data) || {};
};

export const getVideoComplaintById = async (id) => {
  const response = await api.get(`/admin/complaints/videos/${id}`);

  return (response && response.data) || {};
};

export const getCommentComplaintById = async (id) => {
  const response = await api.get(`/admin/complaints/comments/${id}`);

  return (response && response.data) || {};
};

export const getProfileComplaintById = async (id) => {
  const response = await api.get(`/admin/complaints/profiles/${id}`);

  return (response && response.data) || {};
};

export const processVideoComplaint = async ({ id, proceed }) => {
  const response = await api.put(`/admin/complaints/videos/${id}`, { proceed });

  return (response && response.data) || {};
};

export const processCommentComplaint = async ({ id, proceed }) => {
  const response = await api.put(`/admin/complaints/comments/${id}`, { proceed });

  return (response && response.data) || {};
};

export const processProfileComplaint = async ({ id, proceed }) => {
  const response = await api.put(`/admin/complaints/profiles/${id}`, { proceed });

  return (response && response.data) || {};
};
