import React from 'react';

import moment from 'moment';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { format, parseISO, isAfter } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../components/Loading/Loading';
import Tooltip from '../../components/Tooltip/Tooltip';
import LoggedArea from '../../components/LoggedArea/LoggedArea';

import menu from '../../data/enums/menu';
import Feature from '../../data/enums/feature';
import Permission from '../../data/enums/permission';

import numberFormat from '../../utils/numberFormat';
import parseBucketName from '../../utils/parseBucketName';

import { AdActions, UserActions } from '../../redux/actions';

import * as S from './styles';

const AdsPage = () => {
  const {
    ads,
    isLoading,
    advertisers,
    errorMessage,
    isConfirmLoading,
    isRequestCompleted,
  } = useSelector(({ ad }) => ad);

  const { minePermissions } = useSelector(({ user }) => user);

  const [adId, setAdId] = React.useState('');
  const [range, setRange] = React.useState([]);
  const [endAt, setEndAt] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [startAt, setStartAt] = React.useState('');
  const [adViews, setAdViews] = React.useState('');
  const [adType, setAdType] = React.useState('image');
  const [advertiser, setAdvertiser] = React.useState('');
  const [isEditing, setIsEditing] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [externalUrl, setExternalUrl] = React.useState('');
  const [adUrlClicks, setAdUrlClicks] = React.useState('');
  const [fileUploaded, setFileUploaded] = React.useState(null);
  const [advertiserUid, setAdvertiserUid] = React.useState(null);
  const [indeterminate, setIndeterminate] = React.useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = React.useState('');
  const [adVideoFullViews, setAdVideoFullViews] = React.useState('');
  const [showAddOrEditModal, setShowAddOrEditModal] = React.useState(false);

  const widgetRef = React.useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fileMaxSize = 36 * 1024 * 1024; // 36 MB

  const virtualAdvertiserUid = 'abc';
  const newAdvertiser = { uid: virtualAdvertiserUid, name: 'Novo anunciante' };

  const parsedAdvertisers = [newAdvertiser, ...advertisers];

  const checkIndeterminatePeriod = (endDate) => {
    const indeterminatePeriod = '2099-12-31 23:59:00';

    return isAfter(parseISO(endDate), parseISO(indeterminatePeriod));
  };

  const hasAdminPermission = minePermissions
    && minePermissions.find(
      ({ feature, permission }) => feature === Feature.ADS && permission === Permission.ADMIN,
    );

  const cleanInputs = () => {
    setEndAt('');
    setRange([]);
    setTitle('');
    setStartAt('');
    setAdvertiser('');
    setAdType('image');
    setDescription('');
    setExternalUrl('');
    setFileUploaded(null);
    setAdvertiserUid(null);
    setSelectedFileUrl('');
    setIndeterminate(false);
  };

  React.useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  React.useEffect(() => {
    if (isRequestCompleted) {
      cleanInputs();
      setIsEditing(false);
      setShowAddOrEditModal(false);
    }
  }, [isRequestCompleted]);

  React.useEffect(() => {
    dispatch(UserActions.setSelectedMenu(menu.ADS));

    dispatch(AdActions.getAds());
    dispatch(AdActions.getAdvertisers());
  }, []);

  const onCancelAddOrEdit = () => {
    cleanInputs();
    setIsEditing(false);
    setShowAddOrEditModal(false);
  };

  const onCreateOrEditAd = () => {
    if (!advertiserUid) {
      toast.error(t('chooseAdvertiser'));

      return;
    }

    if (advertiserUid === virtualAdvertiserUid && !advertiser) {
      toast.error(t('typeAdvertiserName'));

      return;
    }

    if (!title) {
      toast.error(t('typeAdTitle'));

      return;
    }

    if (!externalUrl) {
      toast.error(t('typeAdURL'));

      return;
    }

    if (!fileUploaded && !isEditing) {
      toast.error(t('insertAdBanner'));

      return;
    }

    const data = new FormData();

    if (advertiserUid !== virtualAdvertiserUid) {
      data.append('advertiserUid', advertiserUid);
    }

    data.append('title', title);
    data.append('description', description);
    data.append('externalUrl', externalUrl);

    if (fileUploaded) {
      data.append('file', fileUploaded);
    }

    if (startAt) {
      data.append(
        'startAt',
        `${format(parseISO(startAt), 'yyyy-MM-dd')} 00:00`,
      );
    } else {
      data.append('startAt', startAt);
    }

    if (endAt) {
      data.append('endAt', `${format(parseISO(endAt), 'yyyy-MM-dd')} 23:59`);
    } else {
      data.append('endAt', endAt);
    }

    if (!isEditing) {
      data.append('type', adType);
    }

    if (advertiserUid === virtualAdvertiserUid) {
      const adData = {
        name: advertiser.trim(),
        bucketName: parseBucketName(advertiser),
      };

      dispatch(
        AdActions.createAdvertiserThenAd({
          adData,
          formData: data,
        }),
      );
    } else if (isEditing) {
      dispatch(
        AdActions.updateAd({
          id: adId,
          formData: data,
        }),
      );
    } else {
      dispatch(AdActions.createAd(data));
    }
  };

  const handleCreateAd = () => {
    if (hasAdminPermission) {
      setShowAddOrEditModal(true);
    } else {
      toast.error(t('noPermission'));
    }
  };

  const handleEditAd = (ad) => {
    if (hasAdminPermission) {
      setIsEditing(true);

      window.scrollTo(0, 0);

      setAdId(ad.id);
      setAdvertiserUid(ad.advertiserUid);
      setTitle(ad.title);
      setDescription(ad.description);
      setStartAt(ad.startAt);
      setEndAt(ad.endAt);
      setRange(
        checkIndeterminatePeriod(ad.endAt)
          ? []
          : [moment(ad.startAt), moment(ad.endAt)],
      );
      setIndeterminate(checkIndeterminatePeriod(ad.endAt));
      setExternalUrl(ad.externalUrl);
      setSelectedFileUrl(ad.fileUrl || ad.videoUrl);
      setAdType(ad.videoUrl ? 'video' : 'image');
      setAdViews(ad.advertisingViews);
      setAdVideoFullViews(ad.advertisingVideoFullViews);
      setAdUrlClicks(ad.advertisingUrlClicks);
      setShowAddOrEditModal(true);
    } else {
      toast.error(t('noPermission'));
    }
  };

  const onRangePickerChange = (dates) => {
    if (dates) {
      setRange(dates);

      setStartAt(format(dates[0]._d, 'yyyy-MM-dd'));
      setEndAt(format(dates[1]._d, 'yyyy-MM-dd'));
    } else {
      setRange([]);
      setEndAt('');
      setStartAt('');
    }
  };

  const onToggleIndeterminate = () => {
    setRange([]);
    setEndAt('');
    setStartAt('');
    setIndeterminate((prevState) => !prevState);
  };

  const AdPreview = () => {
    if (selectedFileUrl && adType === 'image') {
      return (
        <S.AdPreviewWrapper>
          <S.ImagePreview src={selectedFileUrl} />
        </S.AdPreviewWrapper>
      );
    }

    if (selectedFileUrl && adType === 'video') {
      return (
        <S.VideoPlayer
          playing
          url={selectedFileUrl}
          width={320}
          height={180}
          controls
        />
      );
    }

    return null;
  };

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        const fileUrl = URL.createObjectURL(file);

        setSelectedFileUrl(fileUrl);
        setFileUploaded(file);
      }
    },
    [setFileUploaded],
  );

  const onDropRejected = React.useCallback((rejectedFiles) => {
    const { file } = rejectedFiles[0];

    if (file.size > fileMaxSize) {
      toast.error(t('fileTooLarge'));
    } else {
      toast.error(t('somethingWrongOnUpload'));
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    maxFiles: 1,
    accept: `${adType}/*`,
    maxSize: fileMaxSize,
  });

  const onRadioChange = (fileType) => {
    if (isEditing) {
      toast.error(t('editAdTypeNotAllowed'));
    } else {
      setAdType(fileType);
      setFileUploaded(null);
      setSelectedFileUrl('');
    }
  };

  return (
    <LoggedArea>
      <S.Container>
        {isLoading ? (
          <Loading />
        ) : (
          <S.Widget ref={widgetRef}>
            <S.WidgetHeader>
              <S.TableTitle>{t('campaign')}</S.TableTitle>

              <S.TableTitle>{t('period')}</S.TableTitle>

              <S.AddButton onClick={() => handleCreateAd()}>
                {t('add')}
              </S.AddButton>
            </S.WidgetHeader>

            {ads
              ? ads
                .sort((x, y) => y.published - x.published)
                .map((ad) => (
                  <S.Ad key={ad.id} onClick={() => handleEditAd(ad)}>
                    <S.Wrapper>
                      <S.Title>{ad.title}</S.Title>
                      <S.Description>{ad.description}</S.Description>
                    </S.Wrapper>

                    <S.PeriodWrapper>
                      <S.Title>
                        {checkIndeterminatePeriod(ad.endAt)
                          ? t('indeterminate')
                          : `${format(
                            parseISO(ad.startAt),
                            "'de' dd/MM/yy 'à '",
                          )}${format(parseISO(ad.endAt), 'dd/MM/yy')}`}
                      </S.Title>
                    </S.PeriodWrapper>

                    {ad.published ? (
                      <Tooltip title={t('published')}>
                        <S.Published />
                      </Tooltip>
                    ) : null}

                    <Tooltip title={t('edit')}>
                      <S.Edit />
                    </Tooltip>
                  </S.Ad>
                ))
              : null}

            {widgetRef && widgetRef.current ? (
              <S.Modal
                isOpen={showAddOrEditModal}
                shouldCloseOnOverlayClick={false}
                parentSelector={() => widgetRef.current}
                onRequestClose={() => onCancelAddOrEdit()}
              >
                <S.ModalContainer>
                  <S.ModalSection>
                    <S.TitleInputWrapper>
                      <S.Label>{t('advertiser')}</S.Label>

                      <S.AntDSelect
                        bordered={false}
                        value={advertiserUid}
                        disabled={isEditing}
                        onChange={(value) => setAdvertiserUid(value)}
                        placeholder={t('chooseAdvertiser')}
                      >
                        {parsedAdvertisers.map((ad) => (
                          <S.AntDOption key={ad.uid} value={ad.uid}>
                            {ad.name}
                          </S.AntDOption>
                        ))}
                      </S.AntDSelect>
                    </S.TitleInputWrapper>

                    {advertiserUid === virtualAdvertiserUid ? (
                      <S.TitleInputWrapper>
                        <S.Label>{t('newAdvertiser')}</S.Label>
                        <S.Input
                          value={advertiser}
                          maxLength={30}
                          placeholder={t('typeAdvertiserName')}
                          onChange={(e) => setAdvertiser(e.target.value)}
                        />
                      </S.TitleInputWrapper>
                    ) : null}
                  </S.ModalSection>

                  <S.ModalSecondSection>
                    <S.Row>
                      <S.TitleInputWrapper>
                        <S.Label>{t('title')}</S.Label>
                        <S.Input
                          value={title}
                          maxLength={30}
                          placeholder={t('typeAdTitle')}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </S.TitleInputWrapper>

                      <S.DescInputWrapper>
                        <S.Label>{t('description')}</S.Label>
                        <S.Input
                          value={description}
                          maxLength={80}
                          placeholder={t('typeAdDescription')}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </S.DescInputWrapper>
                    </S.Row>

                    <S.Row>
                      <S.TitleInputWrapper>
                        <S.Label>{t('period')}</S.Label>
                        <S.AntDRangePicker
                          value={range}
                          format="DD/MM/YYYY"
                          disabled={indeterminate}
                          onChange={onRangePickerChange}
                        />

                        <S.CheckboxWrapper
                          onClick={() => onToggleIndeterminate()}
                        >
                          <S.Checkbox
                            checked={indeterminate}
                            onChange={() => onToggleIndeterminate()}
                          />
                          <S.CheckboxLabel>
                            {t('indeterminate')}
                          </S.CheckboxLabel>
                        </S.CheckboxWrapper>
                      </S.TitleInputWrapper>

                      <S.DescInputWrapper>
                        <S.Label>{t('site')}</S.Label>
                        <S.Input
                          value={externalUrl}
                          maxLength={500}
                          placeholder={t('typeAdURLPlaceholder')}
                          onChange={(e) => setExternalUrl(e.target.value)}
                        />
                      </S.DescInputWrapper>
                    </S.Row>
                  </S.ModalSecondSection>

                  <S.ModalSecondSection>
                    <S.RadioContainer>
                      <S.AdType>{`${t('adType')}:`}</S.AdType>

                      <S.RadioWrapper onClick={() => onRadioChange('image')}>
                        <S.RadioButton selected={adType === 'image'}>
                          {adType === 'image' ? <S.RadioSelected /> : null}
                        </S.RadioButton>

                        <S.RadioLabel>{t('image')}</S.RadioLabel>
                      </S.RadioWrapper>

                      <S.RadioWrapper onClick={() => onRadioChange('video')}>
                        <S.RadioButton selected={adType === 'video'}>
                          {adType === 'video' ? <S.RadioSelected /> : null}
                        </S.RadioButton>

                        <S.RadioLabel>{t('video')}</S.RadioLabel>
                      </S.RadioWrapper>

                      <S.Dropzone {...getRootProps()}>
                        <S.DropzoneInput {...getInputProps()} />
                        {t('upload')}
                      </S.Dropzone>
                    </S.RadioContainer>

                    <AdPreview />
                  </S.ModalSecondSection>

                  {isEditing ? (
                    <S.AnalyticsSection>
                      <S.Label>
                        {`${t('views')}: ${numberFormat(adViews)}`}
                      </S.Label>
                      {adType === 'video' ? (
                        <S.AdViews>
                          {`${t('untilTheEnd')}: ${numberFormat(
                            adVideoFullViews,
                          )}`}
                        </S.AdViews>
                      ) : null}
                      <S.AdViews>
                        {`${t('clicks')}: ${numberFormat(adUrlClicks)}`}
                      </S.AdViews>
                    </S.AnalyticsSection>
                  ) : null}

                  <S.ModalFooter>
                    <S.ButtonsWrapper>
                      <S.Cancel onClick={() => onCancelAddOrEdit()}>
                        {t('cancel')}
                      </S.Cancel>

                      <S.ConfirmButton onClick={() => onCreateOrEditAd()}>
                        {isConfirmLoading ? (
                          <Loading
                            type="spin"
                            width={20}
                            height={20}
                            color="#000"
                          />
                        ) : (
                          t('confirm')
                        )}
                      </S.ConfirmButton>
                    </S.ButtonsWrapper>
                  </S.ModalFooter>
                </S.ModalContainer>
              </S.Modal>
            ) : null}
          </S.Widget>
        )}
      </S.Container>
    </LoggedArea>
  );
};

export default AdsPage;
