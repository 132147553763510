import React from 'react';
import { Select } from 'antd';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { GiCctvCamera } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import {
  FiX, FiEdit, FiCheck, FiTrash,
} from 'react-icons/fi';

import { placeStatus } from '../../data/enums/place-status';

import 'antd/dist/antd.css';

const { Option } = Select;

const divWidth = 698;

const statusColor = {
  [placeStatus.ONLINE]: '#7dbe31',
  [placeStatus.OFFLINE]: '#fc0021',
  [placeStatus.WARNING]: '#ffcc00',
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8rem;
`;

export const Widget = styled.div`
  position: relative;
  width: 60%;
  min-width: 700px;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 4rem;
  border-radius: 0.8rem;
  border: 1px solid var(--color-form-border);
  background: var(--color-form-background);
`;

export const WidgetHeader = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 2rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-form-border);
`;

export const PlaceTitleWrapper = styled.div`
  width: ${divWidth * 0.45}px;
`;

export const CityTitleWrapper = styled.div`
  width: ${divWidth * 0.25}px;
`;

export const IdTitleWrapper = styled.div`
  width: ${divWidth * 0.2}px;
`;

export const ButtonAddWrapper = styled.div`
  width: ${divWidth * 0.15}px;
  margin-right: 1rem;
`;

export const TableTitle = styled.span`
  color: var(--color-white);
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  margin-right: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.4rem;
  font: 400 1.6rem Maven Pro;
  color: var(--color-black);
  background: var(--color-primary);

  :hover {
    background: var(--color-primary-dark);
  }
`;

export const AddFieldButton = styled(AddButton)`
  margin: 0;
  height: 3rem;
`;

export const ParticipantsButton = styled(AddFieldButton)`
  width: 120px;
`;

export const Place = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: var(--color-background);
  border-bottom: 1px solid var(--color-form-border);
  opacity: ${({ activated }) => (activated ? 1 : 0.3)};

  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: ${divWidth * 0.45}px;

  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  font-size: 1.6rem;
  color: var(--color-white);
`;

export const Address = styled(Name)`
  font-size: 1.2rem;
  color: #ccc;
`;

export const CityWrapper = styled.div`
  width: ${divWidth * 0.25}px;
`;

export const IdWrapper = styled.div`
  width: ${divWidth * 0.2}px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: ${divWidth * 0.15}px;
`;

export const CamIcon = styled(GiCctvCamera).attrs(({ status }) => ({
  size: 22,
  color: statusColor[status] || '#666',
}))`
  cursor: pointer;
`;

export const Edit = styled(FiEdit).attrs({
  size: 22,
  color: 'var(--color-primary)',
})`
  margin-left: 2rem;
  cursor: pointer;
`;

const ReactModalAdapter = ({ className, modalClassName, ...props }) => (
  <ReactModal
    className={modalClassName}
    portalClassName={className}
    bodyOpenClassName="portalOpen"
    {...props}
  />
);

export const Modal = styled(ReactModalAdapter).attrs({
  modalClassName: 'Modal',
  overlayClassName: 'Overlay',
})`
  & .Overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  & .Modal {
    display: flex;
    flex: 1;

    width: 100%;
    padding: 2rem;
    border-radius: 4px;
    border: 1px solid var(--color-form-border);
    background: var(--color-form-background);

    outline: none;

    position: absolute;
    top: 1%;
  }
`;

export const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ModalFirstSection = styled.div`
  flex: 1;
  display: flex;
`;

export const ModalSecondSection = styled(ModalFirstSection)`
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid var(--color-form-border);
`;

export const ModalSportsContainer = styled(ModalSecondSection)`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const ObservationContainer = styled.div`
  margin-top: 1rem;
`;

export const TextAreaWrapper = styled.div``;

export const TextArea = styled.textarea`
  width: 100%;
  height: 10rem;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 0.6rem;
  border: 1px solid var(--color-input-border);
  background: var(--color-input-background);

  resize: none;
`;

export const ModalSportsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
`;

export const AddFieldButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const LabelInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NameInputWrapper = styled(LabelInputWrapper)`
  width: 25%;
  margin-right: 2rem;
`;

export const AddressInputWrapper = styled(LabelInputWrapper)`
  flex: 1;
  margin-right: 2rem;
`;

export const AddressNumberInputWrapper = styled(LabelInputWrapper)`
  width: 10%;
  margin-right: 2rem;
`;

export const ComplementInputWrapper = styled(LabelInputWrapper)`
  width: 20%;
`;

export const Label = styled.label`
  margin-left: 0.5rem;
  color: var(--color-white);
`;

export const Input = styled.input`
  width: 100%;
  height: 3.6rem;
  padding: 0 1rem;
  margin-top: 1rem;
  border-radius: 0.6rem;
  border: 1px solid var(--color-input-border);
  background: var(--color-input-background);
`;

export const RowCenterBtw = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RadioContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;
  margin-left: 1rem;
`;

export const RadioLabel = styled.label`
  margin-left: 1rem;
  margin-right: 2rem;
  color: var(--color-white);

  cursor: pointer;
`;

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ selected }) => (selected ? 'var(--color-primary)' : 'var(--color-white)')};

  cursor: pointer;
`;

export const RadioSelected = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-primary);
`;

export const Dropzone = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 0.4rem;
  font: 400 1.6rem Maven Pro;
  color: var(--color-black);
  background: var(--color-primary);

  cursor: pointer;

  :hover {
    background: var(--color-primary-dark);
  }
`;

export const DropzoneInput = styled.input``;

export const OwnerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
`;

export const AntDSelect = styled(Select)`
  width: 30%;
  height: 3.6rem;
  margin-left: 1rem;
  margin-right: 1.5rem;
  border-radius: 0.6rem;
  border: 1px solid var(--color-input-border);
  background: var(--color-input-background);
`;

export const AntDOption = styled(Option)``;

export const ObservationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
`;

export const Observation = styled.span`
  color: var(--color-primary);
`;

export const FieldsLabel = styled(Label)`
  margin-bottom: 1rem;
`;

export const FieldsContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));
`;

export const FieldCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background: var(--color-background);
  border: 1px solid
    ${({ status }) => statusColor[status] || 'var(--color-form-border)'};
`;

export const SingleFieldCard = styled(FieldCard)`
  max-width: 30rem;
`;

export const FieldTextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalPrivateUsersContainer = styled(ModalContainer)`
  height: 580px;
`;

export const UsernameInputWrapper = styled(NameInputWrapper)`
  width: 210px;
  margin-right: 2rem;
`;

export const UsernameForm = styled.form`
  display: flex;
  align-items: flex-end;
  padding-bottom: 1rem;
  margin-bottom: 12px;
  border-bottom: 2px solid var(--color-form-border);
`;

export const AddPrivateUserButton = styled(AddFieldButton)`
  width: 90px;
  margin-bottom: 2px;
`;

export const UsernamesContainer = styled.main`
  flex: 1;
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  flex-direction: column;
  align-content: flex-start;

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--color-input-background);
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 8px;
  }
`;

export const UsernameCard = styled(FieldCard)`
  width: 210px;
`;

export const UsernameCardDescription = styled(Address)`
  width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ModalFooter = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: flex-end;
`;

export const ModalEditFooter = styled(ModalFooter)`
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxWrapper = styled(ButtonsWrapper)`
  cursor: pointer;
`;

export const CheckboxSportsWrapper = styled(CheckboxWrapper)``;

export const RadioWrapper = styled(ButtonsWrapper)`
  cursor: pointer;
`;

export const ConfirmButton = styled(AddButton)`
  margin: 0;
  min-width: 10rem;
`;

export const Cancel = styled(Name)`
  margin-right: 2rem;
  cursor: pointer;
`;

export const Trash = styled(FiTrash).attrs({
  size: 22,
  color: 'var(--color-red)',
})`
  margin-left: 1rem;
  cursor: pointer;
`;

export const CancelConfirmWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-bottom: 0.5rem;
`;

export const CancelIcon = styled(FiX).attrs({
  size: 22,
  color: 'var(--color-red)',
})`
  cursor: pointer;
`;

export const ConfirmIcon = styled(FiCheck).attrs({
  size: 22,
  color: 'var(--color-green)',
})`
  margin-left: 1rem;
  cursor: pointer;
`;

export const CheckboxLabel = styled.label`
  margin-left: 1rem;
  color: var(--color-white);

  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;

  cursor: pointer;
`;

const Icon = styled.svg`
  fill: none;
  stroke: black;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;
  background: ${({ checked }) => (checked ? 'var(--color-primary)' : 'var(--color-input-background)')};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

export const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export const EmptyStateText = styled.span`
  font-size: 1.8rem;
  color: var(--color-white);
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 4px;
  right: 0;
  background: transparent;
`;

export const CloseIcon = styled(AiOutlineClose).attrs({
  size: 22,
  color: 'var(--color-input-background)',
})``;
