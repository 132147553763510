import { combineReducers } from 'redux';

import ad from './ad';
import user from './user';
import place from './place';
import video from './video';
import complaint from './complaint';
import indicator from './indicator';

const appReducer = combineReducers({
  ad,
  user,
  place,
  video,
  complaint,
  indicator,
});

export default (state, action) => appReducer(state, action);
