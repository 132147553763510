import i18n from 'i18next';

import { AdServices } from '../../services';

import { typeError } from '../../data/enums/error';

export const IS_LOADING = 'IS_LOADING:ad';
export const REQUEST_ERROR = 'REQUEST_ERROR:ad';
export const IS_CONFIRM_LOADING = 'IS_CONFIRM_LOADING:ad';
export const IS_REQUEST_COMPLETED = 'IS_REQUEST_COMPLETED:ad';

export const GET_ADS = 'GET_ADS:ad';
export const GET_ADVERTISERS = 'GET_ADVERTISERS:ad';

export const ADS_VIEWS = 'ADS_VIEWS:ad';
export const ADS_URL_CLICKS = 'ADS_URL_CLICKS:ad';
export const ADS_VIEWS_FULL_VIDEO = 'ADS_VIEWS_FULL_VIDEO:ad';

const startRequest = () => (dispatch) => {
  dispatch({ type: IS_CONFIRM_LOADING, payload: true });
  dispatch({ type: IS_REQUEST_COMPLETED, payload: false });
};

const endRequest = () => (dispatch) => {
  dispatch({ type: REQUEST_ERROR, payload: '' });
  dispatch({ type: IS_CONFIRM_LOADING, payload: false });
  dispatch({ type: IS_REQUEST_COMPLETED, payload: true });
};

export const getAds = () => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    const { advertisements } = await AdServices.getAds();

    dispatch({ type: GET_ADS, payload: advertisements });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
  }
};

export const getAdById = (id) => async (dispatch) => {
  try {
    dispatch({ type: ADS_VIEWS, payload: '' });
    dispatch({ type: ADS_URL_CLICKS, payload: '' });
    dispatch({ type: ADS_VIEWS_FULL_VIDEO, payload: '' });

    const {
      advertisingViews,
      advertisingUrlClicks,
      advertisingVideoFullViews,
    } = await AdServices.getAdById(id);

    dispatch({ type: ADS_VIEWS, payload: advertisingViews });
    dispatch({ type: ADS_URL_CLICKS, payload: advertisingUrlClicks });
    dispatch({ type: ADS_VIEWS_FULL_VIDEO, payload: advertisingVideoFullViews });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  }
};

export const getAdvertisers = () => async (dispatch) => {
  try {
    const { advertisers } = await AdServices.getAdvertisers();

    dispatch({ type: GET_ADVERTISERS, payload: advertisers });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
  }
};

export const createAd = (data) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await AdServices.createAd(data);

    dispatch(getAds());
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};

export const updateAd = (data) => async (dispatch) => {
  try {
    dispatch(startRequest());

    await AdServices.updateAd(data);

    dispatch(getAds());
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch(endRequest());
  }
};

export const createAdvertiserThenAd = ({ adData, formData }) => async (dispatch) => {
  try {
    dispatch(startRequest());

    const { uid } = await AdServices.createAdvertiser(adData);
    dispatch(getAdvertisers());

    formData.append('advertiserUid', uid);
    dispatch(createAd(formData));
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });

    dispatch(endRequest());
  }
};
