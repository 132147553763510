import React from 'react';

import { Tooltip } from 'antd';

import 'antd/dist/antd.css';

const AntDTooltip = ({ title, children }) => (
  <Tooltip title={title}>{children}</Tooltip>
);

export default AntDTooltip;
