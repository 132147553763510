const feature = {
  PLACES: 'places',
  ADS: 'advertising',
  INDICATORS: 'logs',
  TV_VIDEOS: 'tvFtp',
  USERS: 'adminUsers',
  COMPLAINTS: 'complaints',
};

export default feature;
