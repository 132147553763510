import React from 'react';

import { useDispatch } from 'react-redux';

import LoggedArea from '../../components/LoggedArea/LoggedArea';

import menu from '../../data/enums/menu';

import { UserActions } from '../../redux/actions';

import * as S from './styles';

const Home = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(UserActions.setSelectedMenu(menu.HOME));
  }, []);

  return (
    <LoggedArea>
      <S.Container>
        <S.Logo />
      </S.Container>
    </LoggedArea>
  );
};
export default Home;
