import i18n from 'i18next';

const error = {
  NETWORK_ERROR: 'Network Error',
  INVALID_EMAIL: 'auth/invalid-email',
  USER_DISABLED: 'auth/user-disabled',
  FTP_UPLOAD_ERROR: 'ftp-upload-error',
  UNEXPECTED_ERROR: 'unexpected-error',
  USER_NOT_FOUND: 'auth/user-not-found',
  WRONG_PASSWORD: 'auth/wrong-password',
  INVALID_CSV_FORMAT: 'invalid-csv-format',
  USERNAME_NOT_FOUND: 'username-not-found',
  EMAIL_ALREADY_IN_USE: 'email-already-in-use',
  PLACE_FIELD_HAS_VIDEOS: 'place-field-has-videos',
  DOWNLOAD_SERVER_ERROR: 'download-from-server-error',
  PRIVATE_USER_ALREADY_EXISTS: 'private-user-already-exists',
};

export const typeError = new Map([
  [error.INVALID_EMAIL, i18n.t('invalidEmail')],
  [error.NETWORK_ERROR, i18n.t('networkError')],
  [error.USER_DISABLED, i18n.t('userDisabled')],
  [error.USER_NOT_FOUND, i18n.t('userNotFound')],
  [error.WRONG_PASSWORD, i18n.t('wrongPassword')],
  [error.FTP_UPLOAD_ERROR, i18n.t('ftpUploadError')],
  [error.USERNAME_NOT_FOUND, i18n.t('userNotFound')],
  [error.UNEXPECTED_ERROR, i18n.t('unexpectedError')],
  [error.INVALID_CSV_FORMAT, i18n.t('invalidCSVFormat')],
  [error.EMAIL_ALREADY_IN_USE, i18n.t('emailAlreadyInUse')],
  [error.DOWNLOAD_SERVER_ERROR, i18n.t('downloadServerError')],
  [error.PLACE_FIELD_HAS_VIDEOS, i18n.t('placeFieldHasVideos')],
  [error.PRIVATE_USER_ALREADY_EXISTS, i18n.t('privateUserAlreadyExists')],
]);

export default error;
