import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Avatar = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  margin: 2rem;
  border-radius: 50%;
  background: var(--color-primary);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2.4rem;
  font-family: Bebas Neue;
  text-transform: uppercase;
  color: var(--color-black);

  cursor: pointer;
`;
