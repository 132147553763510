import styled from 'styled-components';

import logo from '../../assets/images/logo-yellow.png';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 4rem;
`;

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'TemLance',
})`
  width: 140px;
  height: 140px;
`;
