import i18n from 'i18next';

import { VideoServices } from '../../services';

import { typeError } from '../../data/enums/error';

export const IS_LOADING = 'IS_LOADING:video';
export const REQUEST_ERROR = 'REQUEST_ERROR:video';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS:video';

export const SET_OFFSET = 'SET_OFFSET:video';
export const GET_TV_VIDEOS = 'GET_TV_VIDEOS:video';

export const getTVVideos = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    if (!data.spots) {
      dispatch({ type: SET_OFFSET, payload: 0 });
    }

    const { videos } = await VideoServices.getTVVideos(data);

    const { offset, tvVideos } = getState().video;
    let acumulativeOffset = offset;

    acumulativeOffset += videos.length;

    dispatch({ type: SET_OFFSET, payload: acumulativeOffset });

    if (data.spots) {
      dispatch({ type: GET_TV_VIDEOS, payload: [...tvVideos, ...videos] });
    } else {
      dispatch({ type: GET_TV_VIDEOS, payload: videos });
    }
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
  }
};

export const sendVideosForTV = (data) => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    await VideoServices.sendVideosForTV(data);

    dispatch({ type: REQUEST_SUCCESS, payload: true });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
    dispatch({ type: REQUEST_SUCCESS, payload: false });
  }
};
