import styled from 'styled-components';

const Button = styled.button.attrs({
  type: 'submit',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  height: 3.2rem;
  margin-top: 2rem;
  border-radius: 2rem;
  background: var(--color-primary);

  :hover {
    background: var(--color-primary-dark);
  }
`;

export default Button;
