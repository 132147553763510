import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Routes from './index';
import PrivateRoute from './PrivateRoute';

import Login from '../pages/Login/Login';

import Home from '../pages/Home/Home';
import Complaints from '../pages/Complaints/Complaints';
import Places from '../pages/Places/Places';
import Ads from '../pages/Ads/Ads';
import TVVideos from '../pages/TVVideos/TVVideos';
import Indicators from '../pages/Indicators/Indicators';
import Users from '../pages/Users/Users';

const Content = () => (
  <BrowserRouter>
    <Switch>
      <Route path={Routes.LOGIN} component={Login} />

      <PrivateRoute path={Routes.HOME} exact component={Home} />
      <PrivateRoute path={Routes.COMPLAINTS} component={Complaints} />
      <PrivateRoute path={Routes.PLACES} component={Places} />
      <PrivateRoute path={Routes.ADS} component={Ads} />
      <PrivateRoute path={Routes.TV_VIDEOS} component={TVVideos} />
      <PrivateRoute path={Routes.INDICATORS} component={Indicators} />
      <PrivateRoute path={Routes.USERS} component={Users} />
    </Switch>
  </BrowserRouter>
);

export default Content;
