export default {
  active: 'Ativo',
  activePlural: 'Ativos',
  add: 'Adicionar',
  addField: 'Adicionar Quadra',
  address: 'Endereço',
  admin: 'Admin',
  administrator: 'Administrador',
  ads: 'Anúncios',
  adType: 'Tipo de Anúncio',
  advertiser: 'Anunciante',
  age: 'Idade',
  ageGroups: 'Faixas etárias',
  analyze: 'Analisar',
  appName: 'TemLance',
  areYouSureToSendLessVideos: 'Tem certeza que deseja enviar menos do que {value} vídeos',
  badPlay: 'Bola Murcha',
  bannerAd: 'Imagem do Anúncio',
  basketball: 'Basquete',
  bucket: 'Bucket',
  bucketObservation: 'Nome igual ao que foi cadastrado no local',
  campaign: 'Campanha',
  cancel: 'Cancelar',
  chooseAdvertiser: 'Escolha o anunciante',
  chooseAUser: 'Escolha um usuário',
  city: 'Cidade',
  clicks: 'Clicks',
  comment: 'Comentário',
  commentedBy: 'Comentado por',
  comments: 'Comentários',
  complaintDate: 'Data da Denúncia',
  complaints: 'Denúncias',
  complement: 'Complemento',
  confirm: 'Confirmar',
  created: 'Criados',
  date: 'Data',
  delete: 'Excluir',
  deleted: 'Excluídos',
  description: 'Descrição',
  dontProceed: 'Não procede',
  downloadServerError: 'Erro ao baixar os arquivos do servidor. Tente novamente',
  edit: 'Editar',
  editAdTypeNotAllowed: 'Não é permitido editar o tipo de anúncio',
  editor: 'Editor',
  email: 'E-mail',
  emailAlreadyInUse: 'E-mail já cadastrado',
  emailNotFound: 'e-mail não localizado',
  emailSentSuccessfully: 'E-mail enviado com sucesso!',
  female: 'Feminino',
  fieldAlreadyAdded: 'Quadra já existente',
  fieldName: 'Nome da quadra',
  fields: 'Quadras',
  fileTooLarge: 'Envie um arquivo menor que 36 MB',
  followedProfiles: 'Perfis com Seguidores',
  footvolley: 'Futevôlei',
  forgotPassword: 'Esqueci minha senha',
  ftp: 'FTP',
  ftpUploadError: 'Erro ao enviar os arquivos para o FTP. Tente novamente',
  futsal: 'Futsal',
  gender: 'Gênero',
  genders: 'Gêneros',
  generated: 'Gerados',
  genericError: 'Ocorreu um problema. Tente novamente mais tarde',
  greatPlay: 'Bola Cheia',
  handball: 'Handebol',
  image: 'Imagem',
  indeterminate: 'Indeterminado',
  indicators: 'Indicadores',
  insertAdBanner: 'Insira a imagem ou o vídeo do anúncio',
  insertAtLeastOneField: 'Insira pelo menos uma quadra',
  invalidCSVFormat: 'O formato do CSV não é válido',
  invalidEmail: 'E-mail inválido',
  invalidState: 'Estado inválido',
  invalidZipCode: 'CEP inválido',
  likes: 'Curtidas',
  loadMore: 'Carregar Mais',
  login: 'Login',
  logout: 'Sair',
  mainSports: 'Principais Esportes',
  male: 'Masculino',
  name: 'Nome',
  nameNotRegistered: 'Nome não cadastrado',
  nameWillBeDisplayed: 'Nome que será exibido no App',
  nameWasRegistered: 'Nome cadastrado no local',
  neighborhood: 'Bairro',
  networkError: 'Falha na comunicação com a internet',
  newAdvertiser: 'Novo anunciante',
  noPendingComplaint: 'Nenhuma denúncia de {value} pendente',
  noPermission: 'Sem Permissão',
  noPrivateUsers: 'Nenhum usuário adicionado',
  noResults: 'Sem Resultados',
  notInformed: 'Não informado',
  noVideoFound: 'Nenhum vídeo encontrado',
  number: 'Número',
  observations: 'Observações',
  offline: 'Offline',
  online: 'Online',
  other: 'Outro',
  over50: 'Mais de 50 anos',
  owner: 'Proprietário(a)',
  participants: 'Participantes',
  password: 'Senha',
  period: 'Período',
  permissions: 'Permissões',
  place: 'Local',
  placeFieldHasVideos: 'Não é possível excluir quadra com vídeos',
  places: 'Locais',
  playingAtThisPlace: 'Jogam neste local',
  private: 'Privado',
  privateUserAlreadyExists: 'Usuário já adicionado',
  proceed: 'Procede',
  profile: 'Perfil',
  profiles: 'Perfis',
  profileViews: 'Visualizações de Perfis',
  public: 'Público',
  published: 'Publicado',
  reading: 'Leitura',
  reason: 'Motivo',
  registeredName: 'Nome cadastrado',
  remove: 'Remover',
  removeBeforeLoadMore: 'Remova algum vídeo antes de carregar mais',
  reportedBy: 'Denunciado por',
  reportedProfile: 'Perfil Denunciado',
  resolvedBy: 'Resolvido por',
  saved: 'Salvos',
  seePendings: 'Ver Pendentes',
  seeResolved: 'Ver Resolvidas',
  seeVideo: 'Ver Vídeo',
  selectDesiredLocation: 'Selecione o local desejado',
  send: 'Enviar',
  shares: 'Compartilhamentos',
  site: 'Site',
  soccer: 'Futebol',
  somethingWrongOnUpload: 'Algo errado no upload',
  state: 'Estado',
  title: 'Título',
  total: 'Total',
  tvVideos: 'Vídeos para TVs',
  type: 'Tipo',
  typeAdDescription: 'Digite a descrição do anúncio',
  typeAddress: 'Digite o endereço',
  typeAdTitle: 'Digite o título do anúncio',
  typeAdURL: 'Digite a URL do anúncio',
  typeAdURLPlaceholder: 'https://www.exemplo.com.br',
  typeAdvertiserName: 'Digite o nome do anunciante',
  typeAtLeast6Char: 'Digite pelo menos 6 caracteres',
  typeBucketName: 'Digite o nome do bucket',
  typeCity: 'Digite a cidade',
  typeComplement: 'Digite o complemento',
  typeEmail: 'Digite o e-mail',
  typeFieldName: 'Digite o nome da quadra',
  typeFTPFolder: 'Digite o nome da pasta do FTP',
  typeName: 'Digite o nome',
  typeNeighborhood: 'Digite o bairro',
  typePlaceName: 'Digite o nome do local',
  typeState: 'Digite o estado',
  typeYourEmail: 'Digite seu e-mail',
  typeYourPassword: 'Digite sua senha',
  typeZipCode: 'Digite o CEP',
  uf: 'UF',
  unexpectedError: 'Algo deu errado. Tente novamente',
  untilTheEnd: 'Até o fim',
  upload: 'Upload',
  user: 'Usuário',
  userAlreadyAdded: 'Usuário já adicionado',
  userDisabled: 'Usuário desabilitado',
  userName: 'Nome de usuário',
  userNotFound: 'Usuário não encontrado',
  users: 'Usuários',
  video: 'Vídeo',
  videoNotFound: 'Vídeo não encontrado',
  videos: 'Vídeos',
  videosSentSuccessfully: 'Vídeos enviados com Sucesso!',
  viewer: 'Somente Leitura',
  views: 'Visualizações',
  warning: 'Atenção',
  wrongPassword: 'Senha inválida',
  years: 'anos',
  yes: 'Sim',
  zipCode: 'CEP',
};
