import React from 'react';

import { toast } from 'react-toastify';
import ptBR from 'date-fns/locale/pt-BR';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../components/Loading/Loading';
import Tooltip from '../../components/Tooltip/Tooltip';
import LoggedArea from '../../components/LoggedArea/LoggedArea';

import menu from '../../data/enums/menu';
import Feature from '../../data/enums/feature';
import Permission from '../../data/enums/permission';

import { PlaceActions, UserActions, VideoActions } from '../../redux/actions';

import * as S from './styles';

const TVVideosPage = () => {
  const {
    tvPlaces,
    isLoading: placeIsLoading,
    errorMessage: placeErrorMessage,
  } = useSelector(({ place }) => place);

  const {
    offset,
    tvVideos,
    isLoading,
    errorMessage,
    isRequestSuccess,
  } = useSelector(({ video }) => video);

  const { minePermissions } = useSelector(({ user }) => user);

  const [videos, setVideos] = React.useState([]);
  const [endDate, setEndDate] = React.useState('');
  const [placeId, setPlaceId] = React.useState(null);
  const [startDate, setStartDate] = React.useState('');
  const [selectedVideo, setSelectedVideo] = React.useState({});
  const [radioSelected, setRadioSelected] = React.useState(null);
  const [visibleVideoModal, setVisibleVideoModal] = React.useState(false);

  const defaultVideosToSend = 10;

  const widgetRef = React.useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasAdminPermission = minePermissions
    && minePermissions.find(
      ({ feature, permission }) => feature === Feature.TV_VIDEOS
      && permission === Permission.ADMIN,
    );

  React.useEffect(() => {
    dispatch(UserActions.setSelectedMenu(menu.TV_VIDEOS));

    dispatch(PlaceActions.getTVPlaces());
  }, []);

  React.useEffect(() => {
    if (placeId !== null) {
      setVideos(tvVideos);
    }
  }, [tvVideos]);

  React.useEffect(() => {
    if (placeErrorMessage) {
      toast.error(placeErrorMessage);
    }
  }, [placeErrorMessage]);

  React.useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  React.useEffect(() => {
    if (isRequestSuccess) {
      toast.success(t('videosSentSuccessfully'));
    }
  }, [isRequestSuccess]);

  const onGetTVVideos = () => {
    const data = {
      placeId,
      startDate: `${startDate} 00:00`,
      endDate: `${endDate} 23:59`,
      playScore: radioSelected,
    };

    dispatch(VideoActions.getTVVideos(data));
  };

  const onRangePickerChange = (dates) => {
    if (dates) {
      const [firstDate, secondDate] = dates;

      setStartDate(format(firstDate._d, 'yyyy-MM-dd'));
      setEndDate(format(secondDate._d, 'yyyy-MM-dd'));
    } else {
      setStartDate('');
      setEndDate('');
    }
  };

  const onLoadMoreVideos = () => {
    if (videos.length < defaultVideosToSend) {
      const spots = defaultVideosToSend - videos.length;

      const data = {
        placeId,
        startDate: `${startDate} 00:00`,
        endDate: `${endDate} 23:59`,
        offset,
        spots,
        playScore: radioSelected,
      };

      dispatch({ type: VideoActions.GET_TV_VIDEOS, payload: videos });
      dispatch(VideoActions.getTVVideos(data));
    } else {
      toast.error(t('removeBeforeLoadMore'));
    }
  };

  React.useEffect(() => {
    if (startDate && endDate) {
      onGetTVVideos();
    }
  }, [placeId, startDate, endDate, radioSelected]);

  const onRemoveVideo = (videoId) => {
    if (hasAdminPermission) {
      setVideos(videos.filter((video) => video.id !== videoId));
    } else {
      toast.error(t('noPermission'));
    }
  };

  const onSendVideos = () => {
    if (hasAdminPermission) {
      const data = {
        placeId,
        videos: videos.map((video) => ({ id: video.id })),
      };

      if (videos.length < defaultVideosToSend) {
        /* eslint-disable no-alert */
        // eslint-disable-next-line no-restricted-globals
        if (confirm(t('areYouSureToSendLessVideos')
          .replace('{value}', defaultVideosToSend))) {
          dispatch(VideoActions.sendVideosForTV(data));
        }
      } else {
        dispatch(VideoActions.sendVideosForTV(data));
      }
    } else {
      toast.error(t('noPermission'));
    }
  };

  const onVideoClicked = (video) => {
    window.scrollTo(0, 0);
    setSelectedVideo(video);
    setVisibleVideoModal(true);
  };

  const onRadioSelected = (radio) => {
    if (radio === radioSelected) {
      setRadioSelected(null);
    } else {
      setRadioSelected(radio);
    }
  };

  return (
    <LoggedArea>
      <S.Container>
        {placeIsLoading ? (
          <Loading />
        ) : (
          <S.Content>
            {tvPlaces && tvPlaces.length > 0 ? (
              <S.Header>
                <S.AntDSelect
                  showSearch
                  bordered={false}
                  optionFilterProp="children"
                  onChange={(value) => setPlaceId(value)}
                  placeholder={t('selectDesiredLocation')}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  {tvPlaces.map((place) => (
                    <S.AntDOption key={String(place.id)} value={place.id}>
                      {place.name}
                    </S.AntDOption>
                  ))}
                </S.AntDSelect>

                <S.AntDRangePicker
                  format="DD/MM/YYYY"
                  onChange={onRangePickerChange}
                />
              </S.Header>
            ) : null}

            <S.RadioContainer>
              <S.RadioWrapper onClick={() => onRadioSelected('greatPlay')}>
                <S.RadioButton selected={radioSelected === 'greatPlay'}>
                  {radioSelected === 'greatPlay' ? <S.RadioSelected /> : null}
                </S.RadioButton>

                <S.RadioLabel>{t('greatPlay')}</S.RadioLabel>
              </S.RadioWrapper>

              <S.RadioWrapper onClick={() => onRadioSelected('badPlay')}>
                <S.RadioButton selected={radioSelected === 'badPlay'}>
                  {radioSelected === 'badPlay' ? <S.RadioSelected /> : null}
                </S.RadioButton>

                <S.RadioLabel>{t('badPlay')}</S.RadioLabel>
              </S.RadioWrapper>
            </S.RadioContainer>

            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading ? (
              <Loading />
            ) : (
              videos && videos.length > 0 ? (
                <S.Widget ref={widgetRef}>
                  <S.WidgetHeader>
                    <S.TableTitle>{t('image')}</S.TableTitle>

                    <S.TableTitle>{t('date')}</S.TableTitle>

                    <S.TableTitle>{t('greatPlay')}</S.TableTitle>

                    <S.TableTitle>{t('badPlay')}</S.TableTitle>

                    <S.SendButton onClick={() => onSendVideos()}>
                      {t('send')}
                    </S.SendButton>
                  </S.WidgetHeader>

                  {videos.map((video) => (
                    <S.VideoItem key={video.id}>
                      <Tooltip title={t('seeVideo')}>
                        <S.ThumbnailWrapper onClick={() => onVideoClicked(video)}>
                          <S.Thumbnail src={video.thumbnail} />
                        </S.ThumbnailWrapper>
                      </Tooltip>

                      <S.ItemWrapper>
                        <S.ItemText>
                          {format(parseISO(video.recordedAt),
                            "dd/MM/yy 'às' HH:mm'h'", {
                              locale: ptBR,
                            })}
                        </S.ItemText>
                      </S.ItemWrapper>

                      <S.ItemWrapper>
                        <S.ItemText>{video.greatPlays}</S.ItemText>
                      </S.ItemWrapper>

                      <S.ItemWrapper>
                        <S.ItemText>{video.badPlays}</S.ItemText>
                      </S.ItemWrapper>

                      <S.ItemWrapper>
                        <Tooltip title={t('remove')}>
                          <S.Remove onClick={() => onRemoveVideo(video.id)} />
                        </Tooltip>
                      </S.ItemWrapper>
                    </S.VideoItem>
                  ))}

                  <S.WidgetFooter>
                    <S.LoadMoreButton onClick={() => onLoadMoreVideos()}>
                      {t('loadMore')}
                    </S.LoadMoreButton>
                  </S.WidgetFooter>

                  {widgetRef && widgetRef.current ? (
                    <S.Modal
                      isOpen={visibleVideoModal}
                      parentSelector={() => widgetRef.current}
                      onRequestClose={() => setVisibleVideoModal(false)}
                    >
                      <S.ModalContainer>
                        <S.ModalHeader>
                          <S.ItemText>{selectedVideo.placeName}</S.ItemText>

                          <S.Close onClick={() => setVisibleVideoModal(false)} />
                        </S.ModalHeader>

                        <S.VideoPlayer
                          playing
                          controls
                          width="100%"
                          height="100%"
                          url={selectedVideo.url}
                          onError={() => toast.error(t('videoNotFound'))}
                        />
                      </S.ModalContainer>
                    </S.Modal>
                  ) : null}
                </S.Widget>
              ) : (
                <S.EmptyState>
                  <S.EmptyStateText>{t('noVideoFound')}</S.EmptyStateText>
                </S.EmptyState>
              )
            )}
          </S.Content>
        )}
      </S.Container>
    </LoggedArea>
  );
};
export default TVVideosPage;
