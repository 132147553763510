import api from './api';

export const getAds = async () => {
  const response = await api.get('/advertisements');

  return (response && response.data) || {};
};

export const getAdById = async (id) => {
  const response = await api.get(`/advertisements/${id}`);

  return (response && response.data) || {};
};

export const getAdvertisers = async () => {
  const response = await api.get('/advertisers');

  return (response && response.data) || {};
};

export const createAd = async (payload) => {
  const response = await api.post('/advertisements', payload);

  return (response && response.data) || {};
};

export const updateAd = async ({ id, formData }) => {
  const response = await api.put(`/advertisements/${id}`, formData);

  return (response && response.data) || {};
};

export const createAdvertiser = async (payload) => {
  const response = await api.post('/advertisers', payload);

  return (response && response.data) || {};
};
