import React from 'react';

import Storage, { keys } from '../../data/localStorage';

import * as S from './styles';

const Header = ({ onClick }) => {
  const email = Storage.find(keys.EMAIL);

  return (
    <S.Container>
      <S.Avatar onClick={onClick}>
        {(email && email.charAt(0)) || '?'}
      </S.Avatar>
    </S.Container>
  );
};

export default Header;
