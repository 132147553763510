const menu = {
  HOME: '',
  ADS: 'ads',
  USERS: 'users',
  PLACES: 'places',
  TV_VIDEOS: 'tvVideos',
  COMPLAINTS: 'complaints',
  INDICATORS: 'indicators',
};

export default menu;
