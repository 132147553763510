import axios from 'axios';
import i18n from 'i18next';
import firebase from 'firebase/app';

import { BASE_URL } from '../env.json';

import error from '../data/enums/error';
import Storage, { keys } from '../data/localStorage';

const api = () => {
  const uid = Storage.find(keys.UID);
  const token = Storage.find(keys.TOKEN);

  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
    headers: { uid, token },
  });

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response.status === 401) {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            Storage.store(keys.UID, user.uid);
            Storage.store(keys.TOKEN, user.za);
            Storage.store(keys.EMAIL, user.email);

            window.location.reload();
          } else {
            window.location = '/';
          }
        });
      } else if (err.response.status === 403) {
        window.location = '/';
      }

      if (err.message === error.NETWORK_ERROR) {
        throw new Error(i18n.t('networkError'));
      }

      if (err.response.data.error) {
        throw new Error(err.response.data.error);
      }
    },
  );

  return instance;
};

export default api();
