import { AdActions } from '../actions';

const initialState = {
  errorMessage: '',
  isLoading: false,
  isConfirmLoading: false,
  isRequestCompleted: false,

  ads: [],
  advertisers: [],
  advertisingViews: '',
  advertisingUrlClicks: '',
  advertisingVideoFullViews: '',
};

const ad = (state = initialState, action) => {
  switch (action.type) {
    case AdActions.GET_ADS:
      return {
        ...state,
        ads: action.payload,
      };
    case AdActions.GET_ADVERTISERS:
      return {
        ...state,
        advertisers: action.payload,
      };
    case AdActions.ADS_VIEWS:
      return {
        ...state,
        advertisingViews: action.payload,
      };
    case AdActions.ADS_URL_CLICKS:
      return {
        ...state,
        advertisingUrlClicks: action.payload,
      };
    case AdActions.ADS_VIEWS_FULL_VIDEO:
      return {
        ...state,
        advertisingVideoFullViews: action.payload,
      };
    case AdActions.IS_CONFIRM_LOADING:
      return {
        ...state,
        isConfirmLoading: action.payload,
      };
    case AdActions.IS_REQUEST_COMPLETED:
      return {
        ...state,
        isRequestCompleted: action.payload,
      };
    case AdActions.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case AdActions.REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default ad;
