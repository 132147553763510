import styled from 'styled-components';

import logo from '../../assets/images/logo-yellow.png';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 32rem;
`;

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'TemLance',
})`
  width: 10rem;
  height: 10rem;
  margin: 10rem 0 4rem;
  align-self: center;
`;

export const LoginForm = styled.form.attrs({
  type: 'submit',
})``;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.4rem 2rem;
  border-radius: 0.8rem;
  border: 1px solid var(--color-form-border);
  background: var(--color-form-background);
`;

export const Label = styled.label`
  margin-left: 0.5rem;
  color: var(--color-white);
`;

export const Input = styled.input`
  width: 100%;
  height: 3.6rem;
  padding: 0 1rem;
  margin-top: 1rem;
  border-radius: 0.6rem;
  border: 1px solid var(--color-input-border);
  background: var(--color-input-background);
  margin-bottom: ${({ email }) => (email ? `${1.4}rem` : 0)};
`;

export const RowCenterBtw = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ForgotPassword = styled.a`
  font-size: 14px;
  color: var(--color-hyperlink);
`;
