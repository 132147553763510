import { PlaceActions } from '../actions';

const initialState = {
  errorMessage: '',
  isDeletingUser: [],
  isDeletingField: [],

  isLoading: false,
  isUploading: false,
  isAddingUser: false,
  isGettingUsers: false,
  isConfirmLoading: false,
  isRequestCompleted: false,

  places: [],
  tvPlaces: [],
  placesName: [],
  privateUsers: [],
};

const place = (state = initialState, action) => {
  switch (action.type) {
    case PlaceActions.GET_PLACES:
      return {
        ...state,
        places: action.payload,
      };
    case PlaceActions.GET_PLACES_NAME:
      return {
        ...state,
        placesName: action.payload,
      };
    case PlaceActions.GET_TV_PLACES:
      return {
        ...state,
        tvPlaces: action.payload,
      };
    case PlaceActions.GET_PRIVATE_USERS:
      return {
        ...state,
        privateUsers: action.payload,
      };
    case PlaceActions.IS_CONFIRM_LOADING:
      return {
        ...state,
        isConfirmLoading: action.payload,
      };
    case PlaceActions.IS_REQUEST_COMPLETED:
      return {
        ...state,
        isRequestCompleted: action.payload,
      };
    case PlaceActions.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case PlaceActions.IS_UPLOADING:
      return {
        ...state,
        isUploading: action.payload,
      };
    case PlaceActions.IS_GETTING_USERS:
      return {
        ...state,
        isGettingUsers: action.payload,
      };
    case PlaceActions.IS_ADDING_USER:
      return {
        ...state,
        isAddingUser: action.payload,
      };
    case PlaceActions.IS_DELETING_USER:
      return {
        ...state,
        isDeletingUser: action.payload,
      };
    case PlaceActions.IS_DELETING_FIELD:
      return {
        ...state,
        isDeletingField: action.payload,
      };
    case PlaceActions.REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default place;
