import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Title = styled.p`
  font: 100px Bebas Neue;
  color: var(--color-white);
`;
