import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import UserModal from '../Modal/UserModal';

import Storage, { keys } from '../../data/localStorage';

import { UserActions } from '../../redux/actions';

import * as S from './styles';

const LoggedArea = ({ children }) => {
  const [visibleUserModal, setVisibleUserModal] = React.useState(false);

  const uid = Storage.find(keys.UID);

  const { minePermissions } = useSelector(({ user }) => user);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (minePermissions && !minePermissions.length) {
      dispatch(UserActions.getMinePermissions(uid));
    }
  }, []);

  return (
    <S.Container>
      <Sidebar />

      <S.Main>
        <Header onClick={() => setVisibleUserModal(true)} />

        {children}
      </S.Main>

      <UserModal
        isOpen={visibleUserModal}
        onClose={() => setVisibleUserModal(false)}
      />
    </S.Container>
  );
};

export default LoggedArea;
