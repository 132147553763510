import styled from 'styled-components';
import { Select, DatePicker } from 'antd';

import 'antd/dist/antd.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8rem;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.header`
  width: 60%;
  min-width: 700px;
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
`;

export const AntDSelect = styled(Select)`
  width: 100%;
  margin-right: 2rem;
  height: 3.6rem;
  border-radius: 0.6rem;
  border: 1px solid var(--color-input-border);
  background: var(--color-input-background);
`;

export const AntDOption = styled(Option)``;

export const AntDRangePicker = styled(RangePicker)`
  width: 50%;
  height: 3.6rem;
  border-radius: 0.6rem;
  border: 1px solid var(--color-input-border);
  background: var(--color-input-background);
`;

export const ItemText = styled.span`
  font-size: 1.6rem;
  color: var(--color-white);
`;

export const VideosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UsersContainer = styled(VideosContainer)`
`;

export const Title = styled.span`
  font-size: 2.2rem;
  color: var(--color-primary);
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  color: var(--color-white);
`;

export const UsersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
`;
