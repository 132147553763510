import i18n from 'i18next';

import { IndicatorServices } from '../../services';

import { typeError } from '../../data/enums/error';

export const IS_LOADING = 'IS_LOADING:indicator';
export const REQUEST_ERROR = 'REQUEST_ERROR:indicator';

export const GET_USERS_INDICATORS = 'GET_USERS_INDICATORS:indicator';
export const GET_VIDEOS_INDICATORS = 'GET_VIDEOS_INDICATORS:indicator';

export const cleanIndicators = () => (dispatch) => {
  dispatch({ type: GET_USERS_INDICATORS, payload: {} });
  dispatch({ type: GET_VIDEOS_INDICATORS, payload: {} });
};

export const getIndicators = (data) => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING, payload: true });

    const { usersLog, videosLog } = await IndicatorServices.getIndicators(data);

    dispatch({ type: GET_USERS_INDICATORS, payload: usersLog });
    dispatch({ type: GET_VIDEOS_INDICATORS, payload: videosLog });
  } catch (err) {
    dispatch({
      type: REQUEST_ERROR,
      payload: typeError.get(err.message) || i18n.t('genericError'),
    });
  } finally {
    dispatch({ type: REQUEST_ERROR, payload: '' });
    dispatch({ type: IS_LOADING, payload: false });
  }
};
