import React from 'react';

import ReactLoading from 'react-loading';

const Loading = ({
  type,
  color,
  delay,
  width,
  height,
  className,
}) => (
  <ReactLoading
    delay={delay || 0}
    width={width || 64}
    height={height || 64}
    color={color || '#fff'}
    className={className || ''}
    type={type || 'spinningBubbles'}
  />
);

export default Loading;
