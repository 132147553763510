import React from 'react';

import Loading from '../Loading/Loading';

import Button from './styles';

const AuthButton = ({ isLoading, children, onClick }) => (
  <Button onClick={onClick}>
    {isLoading ? (
      <Loading type="spin" width={20} height={20} color="#000" />
    ) : (
      children
    )}
  </Button>
);

export default AuthButton;
