import React from 'react';

import { Skeleton, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Routes from '../../routes';

import menu from '../../data/enums/menu';

import * as S from './styles';

const Sidebar = () => {
  const {
    isLoading,
    selectedMenu,
    hasAdsPermission,
    hasUsersPermission,
    hasPlacesPermission,
    hasTVVideosPermission,
    hasComplaintsPermission,
    hasIndicatorsPermission,
  } = useSelector(({ user }) => user);

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.MenuWrapper image onClick={() => history.push(Routes.HOME)}>
        <S.Logo />
        <S.Title>{t('appName')}</S.Title>
      </S.MenuWrapper>

      {isLoading ? (
        <Space direction="vertical" size="large">
          <Space>
            <Skeleton.Avatar active size="default" shape="square" />
            <Skeleton.Button active size="default" shape="default" />
          </Space>
          <Space>
            <Skeleton.Avatar active size="default" shape="square" />
            <Skeleton.Button active size="default" shape="default" />
          </Space>
          <Space>
            <Skeleton.Avatar active size="default" shape="square" />
            <Skeleton.Button active size="default" shape="default" />
          </Space>
          <Space>
            <Skeleton.Avatar active size="default" shape="square" />
            <Skeleton.Button active size="default" shape="default" />
          </Space>
          <Space>
            <Skeleton.Avatar active size="default" shape="square" />
            <Skeleton.Button active size="default" shape="default" />
          </Space>
        </Space>
      ) : (
        <>
          {hasComplaintsPermission ? (
            <S.MenuWrapper onClick={() => history.push(Routes.COMPLAINTS)}>
              <S.ComplaintsIcon
                isSelected={selectedMenu.includes(menu.COMPLAINTS)}
              />

              <S.MenuText isSelected={selectedMenu.includes(menu.COMPLAINTS)}>
                {t('complaints')}
              </S.MenuText>
            </S.MenuWrapper>
          ) : null}

          {hasPlacesPermission ? (
            <S.MenuWrapper onClick={() => history.push(Routes.PLACES)}>
              <S.PlacesIcon isSelected={selectedMenu.includes(menu.PLACES)} />

              <S.MenuText isSelected={selectedMenu.includes(menu.PLACES)}>
                {t('places')}
              </S.MenuText>
            </S.MenuWrapper>
          ) : null}

          {hasAdsPermission ? (
            <S.MenuWrapper onClick={() => history.push(Routes.ADS)}>
              <S.AdsIcon isSelected={selectedMenu.includes(menu.ADS)} />

              <S.MenuText isSelected={selectedMenu.includes(menu.ADS)}>
                {t('ads')}
              </S.MenuText>
            </S.MenuWrapper>
          ) : null}

          {hasTVVideosPermission ? (
            <S.MenuWrapper onClick={() => history.push(Routes.TV_VIDEOS)}>
              <S.TVIcon isSelected={selectedMenu.includes(menu.TV_VIDEOS)} />

              <S.MenuText isSelected={selectedMenu.includes(menu.TV_VIDEOS)}>
                {t('tvVideos')}
              </S.MenuText>
            </S.MenuWrapper>
          ) : null}

          {hasIndicatorsPermission ? (
            <S.MenuWrapper onClick={() => history.push(Routes.INDICATORS)}>
              <S.IndicatorsIcon isSelected={selectedMenu.includes(menu.INDICATORS)} />

              <S.MenuText isSelected={selectedMenu.includes(menu.INDICATORS)}>
                {t('indicators')}
              </S.MenuText>
            </S.MenuWrapper>
          ) : null}

          {hasUsersPermission ? (
            <S.MenuWrapper onClick={() => history.push(Routes.USERS)}>
              <S.UsersIcon isSelected={selectedMenu.includes(menu.USERS)} />

              <S.MenuText isSelected={selectedMenu.includes(menu.USERS)}>
                {t('users')}
              </S.MenuText>
            </S.MenuWrapper>
          ) : null}
        </>
      )}
    </S.Container>
  );
};

export default Sidebar;
